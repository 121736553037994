import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "./CarouselImages.scss";
interface Props {
    images: string[];
}
function CarouselImages({ images }: Props) {
    const items = images.map((img, index) => {
        return (
            <Carousel.Item key={index}>
                <img
                    className="d-block w-100"
                    src={img}
                    alt="Imagen del producto"
                />
            </Carousel.Item>
        );
    });
    return <Carousel className="CarouselImages ">{items}</Carousel>;
}

export default CarouselImages;
