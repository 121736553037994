import React from "react";
import { useProductContext } from "../../context/productsContext";
import ProductAdmin from "./ProductAdmin";
import "./Table.scss";
interface Props {
    showSubProducts: boolean;
}
function Table({ showSubProducts }: Props) {
    <script src="https://unpkg.com/bootstrap-table@1.22.1/dist/bootstrap-table.min.js"></script>;
    const { products } = useProductContext();
    return (
        <div className="TableContainer">
            <table className="table table-sm">
                <thead>
                    <tr>
                        {/* <th scope="col">#id</th> */}
                        <th scope="col">Control</th>
                        <th scope="col">Nombre</th>
                        <th scope="col" className="description">
                            Descripción
                        </th>
                        <th scope="col">Precio</th>
                        <th scope="col">Categoría</th>
                        <th scope="col">Marca</th>
                        <th scope="col">Fecha de creación</th>
                        <th scope="col">Ultima modificación</th>
                        <th scope="col">Medidas</th>
                        <th scope="col">Peso</th>
                        <th scope="col">Mínimo</th>
                        <th scope="col">Descuento</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((prod) => {
                        return (
                            <ProductAdmin
                                prod={prod}
                                showSubProducts={showSubProducts}
                                key={prod._id}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
