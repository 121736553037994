//ver https://chatgpt.com/c/135a298d-38ce-427b-b49f-a127b1837a0e

import React, { ChangeEvent, useEffect, useState } from "react";
import { IItem, ISubProduct } from "../../../interfaces/Item";
import axios from "axios";
import Button2 from "../../buttons/Button2/Button2";
import AddImgsSubProducts from "./AddImgsSubProducts/AddImgsSubProducts";
import { useUserContext } from "../../../context/userContext";
import EditUrlImgs from "./EditUrlImgs/EditUrlImgs";
const API_URL = process.env.REACT_APP_API_URL;
interface Props {
    subProd: ISubProduct;
    setEdit: (value: boolean) => void;
}
function EditSubProductInLine({ subProd, setEdit }: Props) {
    // const { idProduct } = useParams();
    const [subProductEdited, setSubProductEdited] =
        useState<ISubProduct>(subProd);
    useEffect(() => {
        setSubProductEdited({
            ...subProductEdited,
            lastModifiedDate: new Date(),
        });
    }, []);
    const handleUrlsChange = (urls: string[]) => {
        setSubProductEdited((prev) => ({ ...prev, img: urls }));
    };
    const handleChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value, name } = e.target;
        console.log(subProductEdited);
        
        setSubProductEdited({ ...subProductEdited, [name]: value });
    };
    const {getToken} =useUserContext()
    const update = async () => {
        try {
            const URL = `${API_URL}/api/subproducts/${subProductEdited._id}`;
            const config = {
                headers: {
                    'Authorization': `Bearer ${getToken()}`,
                }
            };
            console.log('entro hasta aca ',URL);
            
            await axios
                .put(URL, subProductEdited,config)
                .then((data) => console.log("Product updated:", data))
                .then(() => {
                    alert(
                        `Se actualizó el subproducto color ${subProductEdited.color} con id: ${subProductEdited._id}`
                    );
                })
                .then(() => setEdit(false));
        } catch (error) {
            console.error("Error updating product:", error);
        }
    };

    return (
        <tr className="table-primary" key={subProd._id}>
            {/* <th scope="row">{prod._id}</th> */}
            <td>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => update()}
                >
                    Guardar
                </button>
            </td>
            <td>
                <input
                    type="text"
                    value={subProductEdited.color}
                    name="color"
                    onChange={(e) => handleChange(e)}
                />
            </td>

            <td>
                <input
                    type="number"
                    value={subProductEdited.stock}
                    name="stock"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                <input
                    type="text"
                    value={subProductEdited.barcode}
                    name="barcode"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                {/* <Button2 text="Imgs como archivos" /> */}
                <AddImgsSubProducts idSubProduct={subProd._id}/>
                {subProductEdited.img.map((img, index) => (
                    // <img key={index} src={img} alt={`Image ${index}`} />
                    <input
                        key={index}
                        type="text"
                        value={subProductEdited.img}
                        name={`img${index}`}
                        onChange={(e) => handleChange(e)}
                    />
                ))}
            </td>
            <td>
                {new Date(subProductEdited.creationDate).toLocaleDateString(
                    "es-ES",
                    {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    }
                )}
            </td>
            <td>
                {subProductEdited.lastModifiedDate &&
                    new Date(
                        subProductEdited.lastModifiedDate
                    ).toLocaleDateString("es-ES", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
            </td>
            <td>
            <EditUrlImgs
                    arraysUrlImgs={subProductEdited.img}
                    onUrlsChange={handleUrlsChange}
                />
            </td>
        </tr>
    );
}

export default EditSubProductInLine;
