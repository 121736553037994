import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { useUserContext } from "../../context/userContext";
import { IOrder } from "../../interfaces/Orders";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import Button1 from "../buttons/Button1/Button1";
import "./MyOrders.scss";

function MyOrders() {
    const API_URL = process.env.REACT_APP_API_URL;
    const { user } = useUserContext();
    const [orders, setOrders] = useState<IOrder[]>();
    const token = localStorage.getItem("authToken") || undefined;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const orders = await axios.get(
                    `${API_URL}/api/orders/user/${user?._id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const sortedOrders = orders.data.sort((a: IOrder, b: IOrder) => {
                    return new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime();
                });

                setOrders(sortedOrders);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);
    return (
        <div className="MyOrders">
            <h1>Mis pedidos</h1>
            {orders?.map((order) => {
                if (order.state !== "Orden-creada") {
                    const creationDate = dayjs(order.creationDate);
                    const formattedDate = creationDate.format("DD/MM/YYYY");
                    const getOrderStateClass = (state: IOrder["state"]) => {
                        switch (state) {
                            case "En-preparación":
                                return "En-preparación";
                            case "En-camino":
                                return "En-camino";
                            case "recibida":
                                return "recibida";
                            default:
                                return "";
                        }
                    };
                    return (
                        <div
                            key={order._id}
                            className={getOrderStateClass(order.state)}
                        >
                            <Card style={{ width: "90%", margin: "15px auto" }}>
                                <Card.Body>
                                    <h2>
                                        <Card.Title>
                                            Código del pedido: {order._id}
                                        </Card.Title>
                                    </h2>
                                    <Card.Text>
                                        Precio total: ${order.totalPrice}
                                    </Card.Text>
                                    <Card.Text>
                                        Estado de la compra: {order.state.replace(/-/g, ' ')}
                                    </Card.Text>
                                    <Card.Text>
                                        Fecha de creación: {formattedDate}
                                    </Card.Text>
                                </Card.Body>

                                <ListGroup className="list-group-flush">
                                    {/* {
                                            order.cartProducts&&(
                                                order.cartProducts[1]?
                                            <></>
                                            :<></>)
                                        } */}

                                    {order.cartProducts.map((prod) => {
                                        return (
                                            <>
                                                <ListGroup.Item>
                                                    <img
                                                        style={{
                                                            width: "10%",
                                                        }}
                                                        src={
                                                            prod.subProduct
                                                                .img[0]
                                                        }
                                                        alt={`${prod.subProduct.IDProduct?.name} color: ${prod.subProduct.color}`}
                                                    />
                                                    <b>{prod.quantity}</b>{" "}
                                                    unidades de{" "}
                                                    <b>
                                                        {
                                                            prod.subProduct
                                                                .IDProduct?.name
                                                        }{" "}
                                                    </b>
                                                    del color/modelo{" "}
                                                    {prod.subProduct.color}
                                                </ListGroup.Item>
                                            </>
                                        );
                                    })}
                                </ListGroup>
                                {/* <Card.Body>
                                <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link>
                            </Card.Body> */}
                            </Card>
                        </div>
                    );
                }
            })}
            {!orders && (
                <>
                    <p>Todavía no tienes ordenes generadas.</p>
                    <p>Revisa nuestro catálogo:</p>
                    <Link to="catalogo">
                        <Button1 text="Ir al catalogo" />
                    </Link>
                </>
            )}
        </div>
    );
}

export default MyOrders;
