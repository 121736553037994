import React from 'react'
import './Button1.scss'
interface IProps{
    text:string
}
function Button1(props:IProps) {
    return (
        // <div className='ContainerButton'>
            <div className='Button1'>
                {props.text}
            </div>
        // </div>
    )
}

export default Button1