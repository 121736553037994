import React from "react";
import "./CartContainer.scss";
import { useUserContext } from "../../context/userContext";
import { useCartContext } from "../../context/cartContext";
import ItemCart from "./ItemCart";
import Button3 from "../buttons/Button3/Button3";
import Button1 from "../buttons/Button1/Button1";
import { Link } from "react-router-dom";
import { calcularMetrosCuadradosDelPaquete } from "./Volumen";
import Select1 from "../buttons/Select1/Select1";

function CartContainer() {
    const { user } = useUserContext();
    const { cart, cleanCart, totalPriceInCart } = useCartContext();

    // Ejemplo de uso
    const productos = [
        { alto: 10, ancho: 5, largo: 15, peso: 2 },
        { alto: 8, ancho: 4, largo: 12, peso: 1.5 },
        { alto: 6, ancho: 3, largo: 10, peso: 1 },
    ];

    const metrosCuadradosDelPaquete =
        calcularMetrosCuadradosDelPaquete(productos);
    // console.log("Metros cuadrados del paquete:", metrosCuadradosDelPaquete);
    const raizCubica = Math.pow(metrosCuadradosDelPaquete, 1 / 3);

    return (
        <div className="CartContainer">
            {/* "Lados del paquete:", {raizCubica}
            "Metros cuadrados del paquete:", {metrosCuadradosDelPaquete} */}
            <h1>Carrito</h1>
            {user ? (
                <>
                    <div className="Items">
                        {cart?.products.map((prod) => {
                            return <ItemCart prod={prod} key={prod._id} />;
                        })}
                    </div>
                    {totalPriceInCart !== 0 ? (
                        <>
                            <p>Subtotal sin envío ${totalPriceInCart}</p>
                            {/* <select name="envio" id="envio">
                                <option value="giles">Envío en San Andres de Giles</option>
                                <option value="retiro">Retiro en San Andres de Giles</option>
                                <option value="correo-argentino">Envío a otra parte de Argentina</option>
                            </select> */}
                            <div onClick={() => cleanCart()}>
                                <Button3 text="Vaciar carrito" />
                            </div>
                            <Link to='/order'>
                                <Button1 text="Iniciar compra" />
                            </Link>
                        </>
                    ) : (
                        <>
                            No tienes productos en el carrito
                            <Link to="/catalogo">
                                <Button1 text="Ir a comprar productos" />
                            </Link>
                        </>
                    )}
                </>
            ) : (
                <>
                    No tienes una sesión activa
                    <Link to="/login">
                        <Button1 text="Iniciar sesión" />
                    </Link>
                </>
            )}
            {/* </div> */}
        </div>
    );
}

export default CartContainer;
