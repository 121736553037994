import React, { FormEvent, useState } from "react";
import NewSubProductsContainer from "../NewProduct/NewSubProductsContainer";
import "../NewProduct/NewProduct.scss";
import { getToken } from "../../../usefulFunctions";
import axios from "axios";
import { ISubProduct } from "../../../interfaces/Item";
import { useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

function AddMoreSubProducts() {
    const { idProduct } = useParams();
    const initialForm: { idProduct: string; subProducts: ISubProduct[] } = {
        idProduct: idProduct || "",
        subProducts: [],
    };
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [formData, setFormData] = useState(initialForm);
    const [quantity, setQuantity] = useState(1);

    const addSubProduct = (sub: ISubProduct) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subProducts: [...prevFormData.subProducts, sub],
        }));
    };
    const Submit = async (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log({ formData });
        const URL = `${API_URL}/api/products/add-more-sub-product`;
        const token = getToken();
        try {
            const response = await axios.post(URL, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Respuesta del servidor:", response.data);
            // .then(()=>alert('Producto creado con exito'))
            // .catch(()=>alert('No se pudo crear el producto'))
            setFormData(initialForm);
            // setSubmitted(!submitted);
            alert("Producto agregado con éxito");
        } catch (error) {
            console.log(error);
            alert("No se pudo cargar el producto");
        }
    };
    return (
        <form className="NewProduct">
            <h1>Agregar subproducto</h1>
            <NewSubProductsContainer
                cantidad={quantity}
                addSubProduct={addSubProduct}
                Submit={Submit}
                submitted={submitted}
            />
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                    quantity > 1 && setQuantity(quantity - 1);
                }}
            >
                restar subproducto
            </button>
            <button
                type="button"
                className="btn btn-warning"
                onClick={() => setQuantity(quantity + 1)}
            >
                Agregar subproducto
            </button>
            <p>Cantidad de subproductos: {quantity}</p>
            {submitted ? (
                <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={(e) => Submit(e)}
                    // disabled={submitted ? false : true}
                >
                    Enviar
                </button>
            ) : (
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        setSubmitted(!submitted);
                    }}
                >
                    Agregar subproductos
                </button>
            )}
        </form>
    );
}

export default AddMoreSubProducts;
