import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ProductsContextProvider from "./context/productsContext";
import { SearchContextProvider } from "./context/searchContext";
import UserContextProvider from "./context/userContext";
import CartContextProvider from "./context/cartContext";
import { BrowserRouter as Router }from "react-router-dom";
import ScrollToTop from "./config/ScrollTop";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <Router >
            <ScrollToTop />
            <ProductsContextProvider>
                <SearchContextProvider>
                    <UserContextProvider>
                        <CartContextProvider>
                            <App />
                        </CartContextProvider>
                    </UserContextProvider>
                </SearchContextProvider>
            </ProductsContextProvider>
        </Router>
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom/client";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import ProductsContextProvider from "./context/productsContext";
// import { SearchContextProvider } from "./context/searchContext";
// import UserContextProvider from "./context/userContext";
// import CartContextProvider from "./context/cartContext";
// import { BrowserRouter as Router } from "react-router-dom";
// import ScrollToTop from "./config/ScrollTop";

// const root = ReactDOM.createRoot(
//     document.getElementById("root") as HTMLElement
// );
// root.render(
//     <Router>
//         <ScrollToTop />
//         <ProductsContextProvider>
//             <SearchContextProvider>
//                 <UserContextProvider>
//                     <CartContextProvider>
//                         <App />
//                     </CartContextProvider>
//                 </UserContextProvider>
//             </SearchContextProvider>
//         </ProductsContextProvider>
//     </Router>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
