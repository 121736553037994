import axios from "axios";
import React, { useState } from "react";
import Button1 from "../../buttons/Button1/Button1";
import { useParams } from "react-router-dom";
import { getToken } from "../../../usefulFunctions";

function EditState() {
    const api = process.env.REACT_APP_API_URL;
    const [state, setState] = useState<
        "Orden-creada" | "En-preparación" | "En-camino" | "recibida"
    >("En-preparación");
    const { idOrder } = useParams();
    const editState = async () => {
        const config = {
            headers: {
                Authorization: `Bearer ${getToken()}`,
            },
        };        
        const fetchData = await axios.put(
            `${api}/api/orders/${idOrder}/state/${state}`,
            {},
            config
        );
        fetchData && alert("Se cambió el estado a la orden");
    };
    return (
        <div>
            <h1>editar estado</h1>
            <select
                onChange={(e) =>
                    setState(
                        e.target.value as
                            | "En-preparación"
                            | "En-camino"
                            | "recibida"
                    )
                }
            >
                <option value="En-preparación" key="En-preparación">
                    En-preparación
                </option>
                <option value="En-camino" key="En-camino">
                    En-camino
                </option>
                <option value="recibida" key="recibida">
                    recibida
                </option>
            </select>
            <div onClick={() => editState()}>
                <Button1 text="modificar estado" />
            </div>
            {state}
        </div>
    );
}

export default EditState;
