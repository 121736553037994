import React, { useEffect, useState } from "react";
import { IItem } from "../../interfaces/Item";
import "./Item.scss";
import { thereIsStockInOneSub } from "./function";
import { LazyLoadImage } from 'react-lazy-load-image-component';
interface Props {
    product: IItem;
}
function Item({ product }: Props) {
    const [stock, setStock] = useState(false);

    useEffect(() => {
        thereIsStockInOneSub({product,setStock})
    }, []);
    return (
        <div className={`item ${!stock && "without-Stock-item"}`}>
            {!stock && <div className="targetWithoutStock">Sin stock</div>}
            {
                <LazyLoadImage
                    src={
                        product.IDSubProducts[0].img[0]
                            ? product.IDSubProducts[0].img[0]
                            : process.env.PUBLIC_URL + "/images/logo.png"
                    }
                    alt={product.name}
                    placeholderSrc="/images/icons/loading.svg"
                />
            }
            {product.IDSubProducts[1] && (
                <div className="moreColors">Hay mas opciones de color, click aquí</div>
            )}
            <h3>{product.name}</h3>
            <p>
                {" "}
                <b> ${product.price} </b>{" "}
            </p>
        </div>
    );
}

export default Item;
