import React from "react";
import "./IdeasCreativas.scss";
import "../pages.scss";
import { IdeasCreativasList } from "./IdeasCreativasList";
import IdeasCreativasItem from "./IdeasCreativasItem";
import { Link } from "react-router-dom";


function IdeasCreativas() {
    return (
        <div className="IdeasCreativasContainer pages">
            <h1>Ideas creativas</h1>
            <p>
                Proximamente aquí aparecerán ideas para hacer con los productos
                que compres en <b>rayu</b>.
            </p>
            <Link to={"/"} className="logo">
                <img
                    style={{ width: "30vw" }}
                    className="logo"
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="Logo de Rayuela"
                />
            </Link>
            {/* {IdeasCreativasList.map((idea) => (                
              <IdeasCreativasItem idea={idea} />
            ))} */}
        </div>
    );
}

export default IdeasCreativas;
