import React, { useState } from "react";
import "./Login.scss";
import axios from "axios";
import { useUserContext } from "../../../context/userContext";
import Button1 from "../../buttons/Button1/Button1";
import LoginGoogleButton from "../LoginGoogleButton/LoginGoogleButton";

function Login() {
    const { updateInfoUser } = useUserContext();
    const urlApi = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const saveTokenToLocalStorage = (token: string | null) => {
        return new Promise<void>((resolve, reject) => {
            try {
                token && localStorage.setItem("authToken", token);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
    const sendForm = async () => {
        const formData = { email, password };
        try {
            const response = await axios.post(
                `${urlApi}/api/user/login`,
                formData
            );
            if (response.status === 200) {
                const authToken = await response.data.token; // Ajusta esto según la respuesta real del servidor
                saveTokenToLocalStorage(authToken);
                console.log("Token recibid/o:", authToken);
                updateInfoUser(authToken);
            } else {
                alert('Usuario o contraseña incorrecta, intenta de nuevo')
                console.error("Error al iniciar sesión");
            }
        } catch (error) {
            alert('Usuario o contraseña incorrecta, intenta de nuevo')
            console.error("Error en la solicitud:", error);
        }
    };


    return (
        <main className="Login">
            <form>
                <label htmlFor="email">Dirección de Email</label>
                <input
                    type="email"
                    placeholder="nombre@ejemplo.com"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label
                htmlFor="password">Contraseña</label>
                <input
                    type="password"
                    placeholder="Introduce tu contraseña"
                    name="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <div onClick={() => sendForm()}>
                    <Button1 text="Iniciar Sesión" />
                </div>
            </form>
            <LoginGoogleButton/>
        </main>
    );
}

export default Login;
