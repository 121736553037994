import { IItem, ISubProduct } from "../../interfaces/Item";
interface PropsThereIsStockInOneSub{
    product:IItem,
    setStock:(a:boolean)=>void
}
export const thereIsStockInOneSub = ({product,setStock}:PropsThereIsStockInOneSub) => {
    product.IDSubProducts.forEach((sub) => {
        if (sub.stock > 0) {
            setStock(true);
        }
    });
};
interface PropsThereIsStockInThisSub{
    subProduct:ISubProduct,
    setStock:(a:boolean)=>void
}
export const thereIsStockInThisSub = ({subProduct,setStock}: PropsThereIsStockInThisSub) => {
        if (subProduct.stock > 0) {
            setStock(true);
        }else{setStock(false)}
};

