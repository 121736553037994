import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from 'aos'
import 'aos/dist/aos.css'
interface Props {
    category: string;
    img: string;
}
export default function Category({ category, img }: Props) {
    useEffect(()=>{
        const timeoutId = setTimeout(() => {
            AOS.init({ duration: 2000 });
        }, 1000)
    },[])
    return (
        <div className="itemCategory" data-aos="fade-up">
            <Link to={`/catalogo/categoria/${category.replace(/\s+/g, "-")}`}>
            <img src={img} alt={`imagen de la categoria ${category}`} />

            <div className="text">{category}</div>
            </Link>
        </div>
    );
}
