import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { IUser } from "../../../interfaces/User";
import { Link } from "react-router-dom";
import { useUserContext } from "../../../context/userContext";
import { useCartContext } from "../../../context/cartContext";
import Offcanvas from "react-bootstrap/Offcanvas";

import "./AsideUser.scss";
interface Props {
    user: IUser | undefined;
}
function AsideUser({ user }: Props) {
    const { updateInfoCart } = useCartContext();
    const { closeSession } = useUserContext();
    const Logout = () => {
        localStorage.removeItem("authToken");
        closeSession();
        updateInfoCart();
    };
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const showImg = (
        <>
            {user ? (
                <img
                    src={`${
                        user?.img
                            ? user.img
                            : process.env.PUBLIC_URL +
                              "/images/icons/user-white-connected.svg"
                    }`}
                    className={`${user?.img ? "photoUser" : ""}`}
                    alt="Imagen del usuario"
                />
            ) : (
                <img
                    src={`${
                        process.env.PUBLIC_URL + "/images/icons/user-white.svg"
                    }`}
                    // className={`${user?.img ? "photoUser" : ""}`}
                    alt="Imagen del usuario"
                />
            )}
        </>
    );
    return (
        <div className="AsideUser">
            <Dropdown>
                <Dropdown.Toggle
                    variant="success"
                    id="dropdown-basic"
                    onClick={handleShow}
                >
                    <div className="userIcon">
                        {showImg}
                    </div>
                </Dropdown.Toggle>

                {/* <Dropdown.Menu> */}
                {/* </Dropdown.Menu> */}
            </Dropdown>

            <Offcanvas show={show} onHide={handleClose} placement="end">
                <div className="OffCanvas">
            <Offcanvas.Header>
            <button type="button" className="btn-close  btn-close-white" aria-label="Close" onClick={()=>setShow(false)}></button>
            </Offcanvas.Header>
                    {user ? (
                        <div>
                            <h4>{user?.name}</h4>
                            <div className="imgInAside">

                            {showImg}
                            </div>
                            <hr />
                            {user.rol === "admin" && (
                                <>
                                    <Dropdown.Item onClick={()=>setShow(false)}>
                                        <Link to="/admin">Administración</Link>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>setShow(false)}>
                                        <Link to="/admin/new-product">
                                            Nuevo Producto
                                        </Link>{" "}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={()=>setShow(false)}>
                                        <Link to="/admin/order">Ordenes</Link>{" "}
                                    </Dropdown.Item>
                                </>
                            )}
                            <Dropdown.Item onClick={()=>setShow(false)}>
                                <Link to="/mis-pedidos">Mis pedidos</Link>{" "}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={()=>setShow(false)}>
                                <Link to="/info-user">Datos de usuario</Link>{" "}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => Logout()}>
                                Cerrar Sesión
                            </Dropdown.Item>
                        </div>
                    ) : (
                        <div>
                            <Dropdown.Item onClick={()=>setShow(false)}>
                                <Link to="/login">Iniciar Sesión</Link>
                            </Dropdown.Item>

                            <Dropdown.Item onClick={()=>setShow(false)}>
                                <Link to="/register">Crear Cuenta</Link>
                            </Dropdown.Item>
                        </div>
                    )}
                </div>
            </Offcanvas>
        </div>
    );
}

export default AsideUser;