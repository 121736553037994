import React, { useState } from "react";
import './SelectColor.scss'
import { ISubProduct } from "../../../interfaces/Item";

interface Options {
    subProducts: ISubProduct[];
    changeSubProduct:(IDSubProduct:string)=>void
}
function SelectColor({ subProducts,changeSubProduct }: Options) {
    const handleColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value; // Obtiene el valor seleccionado del select
        changeSubProduct(selectedValue); // Pasa el valor al componente padre
    };

    return (
        <div className="btnSelectColor">
            <select name="color" id="colores" onChange={e=>handleColorChange(e)}>
                {subProducts.map((option) => (
                    <option value={option._id} key={option._id}>
                        Color: {option.color} {option.stock < 1 && '(Sin stock)'}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default SelectColor;
