import React from "react";
import { useProductContext } from "../../context/productsContext";
import { useSearchContext } from "../../context/searchContext";
import Loading from "../../Loading/Loading";
import Search from "../Header/Search/Search";
import "../Header/Search/Search.scss";
import ItemList from "./ItemList";
import "./ItemListContainer.scss";
import SelectOrderProducts from "../buttons/SelectOrderProducts/SelectOrderProducts";

function ItemListContainer() {
    const { showProducts,loading, categoryContext,brandContext } = useProductContext();
    const { search } = useSearchContext();

    const arraySort=[
        {
            text:'Por nombre',
            value:'SortByName'
        },
        {
            text:'Menor Precio',
            value:'SortByMinorPrice'
        },
        {
            text:'Mayor Precio',
            value:'SortByMajorPrice'
        }
    ]

    return (
        <>
            {loading ? (
                // "cargando"
                <Loading/>
            ) : (
                <div className="ItemListContainer">
                    <h1>Catálogo</h1>

                    {categoryContext&&<h2>Categoría: {categoryContext.replace(/-/g, " ")}</h2>}
                    <SelectOrderProducts array={arraySort} />
                    {brandContext&&<h2>Marca: {brandContext.replace(/-/g, " ")}</h2>}
                    <div className="search">
                        <Search />
                    </div>
                    {showProducts && <ItemList products={showProducts} />}
                    {!showProducts && (
                        <p>
                            No hay productos que coincidan con <b>{search}</b>
                        </p>
                    )}
                </div>
            )}
        </>
    );
}

export default ItemListContainer;
