import React from "react";
import "./LoginGoogleButton.scss";

function LoginGoogleButton() {
    const handleGoogleLogin = (): void => {
        try {
            window.location.href = `${process.env.REACT_APP_API_URL}/api/user/google`;
        } catch (err) {
            console.log(err);
        }
    }; 
    return (
        <div className="google-btn" onClick={handleGoogleLogin}>
            <div className="google-icon-wrapper">
                <img
                    className="google-icon"
                    src={`${process.env.PUBLIC_URL}/images/icons/google.png`}
                    alt="Logo de google, para iniciar sesión"
                />
            </div>
            <p className="btn-text">
                <b>Iniciar sesión con google</b>
            </p>
        </div>
    );
}

export default LoginGoogleButton;
