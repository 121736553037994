import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button1 from "../../buttons/Button1/Button1";
import "./Register.scss";
import { IUser } from "../../../interfaces/User";
import axios from "axios";
import LoginGoogleButton from "../LoginGoogleButton/LoginGoogleButton";

function Register() {
    const urlApi = process.env.REACT_APP_API_URL;
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [name, setName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const sendForm = async() => {
        // Validaciones básicas
        if (!email || !password || !name || !lastName) {
            alert("Por favor, completa todos los campos requeridos.");
            return;
        }
        if (password !== confirmPassword) {
            alert("Las contraseñas no coinciden.");
            return;
        }

        // Aquí puedes enviar los datos al servidor o realizar otras acciones necesarias
        const newUser={
            email,password,name,lastName
        }
        try {
            await axios.post(`${urlApi}/api/user/signup`,newUser)
            return alert('Usuario creado, ahora solo tienes que iniciar sesión')
        } catch (error) {
            return console.log(error);
        }        
    };
    return (
        <div className="Register">
            <Form>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Dirección de Email</Form.Label>
                    <Form.Control
                        type="email"
                        placeholder="nombre@ejemplo.com *"
                        name="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="Introduce tu contraseña *"
                        name="password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Confirmar contraseña</Form.Label>
                    <Form.Control
                        type="password"
                        placeholder="confirma tu contraseña *"
                        name="confirmPassword"
                        value={confirmPassword}
                        required
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Nombre</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tu nombre *"
                        name="name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                >
                    <Form.Label>Apellido</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Tu apellido *"
                        name="lastName"
                        value={lastName}
                        required
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Form.Group>
                <div onClick={() => sendForm()}>
                    <Button1 text="Registrarse" />
                </div>
            </Form>
            <LoginGoogleButton/>
        </div>
    );
}

export default Register;
function express() {
    throw new Error("Function not implemented.");
}

