import React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Aside.scss";
import { useProductContext } from "../../context/productsContext";
import { Accordion } from "react-bootstrap";
import { deleteDuplicated, orderAlphabetically } from "../../usefulFunctions";

function Aside() {
    const [valueAside, setValueAside] = useState(false);
    const [categories, setCategories] = useState<string[]>([]);
    const [brands, setBrands] = useState<string[]>([]);
    const { products } = useProductContext();

    useEffect(() => {
        let listCategories: string[] = [];
        products.forEach((prod) => {
            listCategories = [
                ...listCategories,
                prod.category.replaceAll("-", " "),
            ];
        });
        listCategories= orderAlphabetically(listCategories)
        listCategories=deleteDuplicated(listCategories)
        
        setCategories(listCategories);

        let listBrands: string[] = [];
        products.forEach((prod) => {
            listBrands = [...listBrands, prod.brand.replaceAll("-", " ")];
        });
        listBrands = orderAlphabetically(listBrands)  
        listBrands=deleteDuplicated(listBrands)
        setBrands(listBrands);
    }, [products]);
    const changeAside = () => {
        setValueAside(!valueAside);
    };
    return (
        <>
            <div className="aside">
                <button
                    className={`btnAside ${valueAside && "activeBtnAside"}`}
                    onClick={() => changeAside()}
                    >
                    {valueAside ? (
                        <img
                        src="/images/icons/flecha-blanca-aside-open.svg"
                        alt="Abrir menú de categorías"
                        />
                        ) : (
                            <img
                            src="/images/icons/flecha-blanca-aside-close.svg"
                            alt="Cerrar menú de categorías"
                            />
                            )}
                </button>
                <aside className={`${valueAside && "active"}`} >
                            <h2>Filtrar por:</h2>
                    <Accordion defaultActiveKey="0" flush>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                Categorías
                            </Accordion.Header>
                            <Accordion.Body>
                            <ul>
                        {categories.map((category) => {
                            const idCategory = category.replaceAll(" ", "-");
                            return (
                                <li key={idCategory}>
                                    <NavLink
                                        to={`/catalogo/categoria/${idCategory}`}
                                        onClick={() => changeAside()}
                                    >
                                        {category}
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Marcas
                            </Accordion.Header>
                            <Accordion.Body>
                            <ul>
                        {brands.map((brand) => {
                            const idBrand = brand.replaceAll(" ", "-");
                            return (
                                <li key={idBrand}>
                                    <NavLink
                                        to={`/catalogo/marca/${idBrand}`}
                                        onClick={() => changeAside()}
                                    >
                                        {brand}
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </aside>
            </div>
        </>
    );
}

export default Aside;
