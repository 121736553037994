import React from "react";
import Carrousel from "./Carousel/CarouselIndex";
import GoCatalogue from "./GoCatalogue/GoCatalogue";
import "./Index.scss";
import CategoriesIndex from "./Categories/CategoriesIndex";
import BrandsIndex from "./BrandsIndex/BrandsIndex";

function Index() {
    return <div className="Index">
        <Carrousel/>
        <GoCatalogue/>
        <CategoriesIndex/>
        {/* <BrandsIndex/> */}
    </div>;
}

export default Index;
