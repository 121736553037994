import React from "react";
import "./Loading.scss";

function Loading() {
    return (
        <div className="Loading">
            {/* <iframe
                src="https://giphy.com/embed/swhRkVYLJDrCE"
                width="480"
                height="270"
                frameBorder="0"
                className="giphy-embed"
                allowFullScreen
            ></iframe> */}
            <img src={process.env.PUBLIC_URL + "/images/icons/loading.svg"} alt="loading" />
        </div>
    );
}

export default Loading;
