import React, { useState } from "react";
import "./SelectOrderProducts.scss";
import { ISort, useProductContext } from "../../../context/productsContext";

interface Options {
    array: { text: string; value: string }[];
    // changeSubProduct:(IDSubProduct:string)=>void
}
function SelectColor({ array }: Options) {
    const { setSort } = useProductContext();
    const handleColorChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSort({sort:event.target.value}as ISort);
    };

    return (
        <div className="btnSelect">
            Ordenar:
            <select
                name="color"
                id="colores"
                onChange={(e) => handleColorChange(e)}
            >
                {array.map((data) => (
                    <option value={data.value} key={data.value}>
                        {data.text}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default SelectColor;
