import React from "react";
import "./Select1.scss";

interface Options {
    array: { text: string; value: string;}[];
    setState:React.Dispatch<React.SetStateAction<any>>,
    state:string,
    title?:string
}
function Select1({ array, setState, state, title}: Options) {

    return (
        <div className="btnSelect">
            {title && `${title}:  `}
            <select
                name="color"
                id="colores"
                value={state}
                onChange={(e) => {setState(e.target.value); console.log(e.target.value);
                }}
            >
                {array.map((data) => (
                    <option value={data.value} key={data.value}>
                        {data.text}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default Select1;
