import React, { useState } from "react";
import "./InfoUser.scss";
import { useUserContext } from "../../../context/userContext";
import Button2 from "../../buttons/Button2/Button2";
import Button1 from "../../buttons/Button1/Button1";
import EditInfoUser from "./EditInfoUser/EditInfoUser";
import EditInfoImgUser from "./EditInfoImgUser/EditInfoImgUser";
import Direction from "./Direction";

function InfoUser() {
    const { user } = useUserContext();
    const [isEditing, setIsEditing] = useState(false);
    const [isUploadingImg, setIsUploadingImg] = useState(false);

    return (
        <div className="InfoUser">
            {(isUploadingImg && user) ? (
                <EditInfoImgUser setIsUploadingImg={setIsUploadingImg} user={user} />
            ) : (
                <>
                    <aside>
                        {user?.img ? (
                            <img
                                src={user.img}
                                alt={`Img del usuario ${user.img}`}
                            />
                        ) : (
                            <img
                                src={`${
                                    process.env.PUBLIC_URL +
                                    "/images/icons/user-white-connected.svg"
                                }`}
                                alt="Imagen del usuario"
                            />
                        )}
                        <div onClick={() => setIsUploadingImg(!isUploadingImg)}>
                            <Button1
                                text={
                                    user?.img
                                        ? "Cambiar imagen"
                                        : "Subir Imagen"
                                }
                            />
                        </div>
                    </aside>

                    <main>
                        {isEditing && user ? (
                            <EditInfoUser
                                setIsEditing={setIsEditing}
                                user={user}
                            />
                        ) : (
                            <>
                                <h1>Datos de usuario</h1>
                                <p>Nombre: {user?.name}</p>
                                <p>Apellido: {user?.lastName}</p>
                                <p>Email: {user?.email}</p>
                                <p>
                                    Teléfono:{" "}
                                    {user?.phone
                                        ? user.phone
                                        : "No definió un número telefónico"}
                                </p>
                                <Direction user={user}/>
                                <div onClick={() => setIsEditing(!isEditing)}>
                                    <Button2 text={"Modificar Información"} />
                                </div>
                            </>
                        )}
                    </main>
                </>
            )}
        </div>
    );
}

export default InfoUser;
