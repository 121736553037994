import React from "react";
import { Link } from "react-router-dom";
import Button1 from "../../buttons/Button1/Button1";
import "../../pages/pages.scss";

function Success() {
    return (
        <div className="pages">
            <h1>El pago fué realizado con exito</h1>
            <p>Puedes ver tu orden en:</p>
            <Link to="/mis-pedidos">
                <Button1 text="Ver mis ordenes" />
            </Link>
        </div>
    );
}

export default Success;
