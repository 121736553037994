import React, { useState } from "react";
import Table from "./Table";
import "./Admin.scss";
import { Link } from "react-router-dom";

function Admin(/* proucts:<Array<IItem>> */) {
    const [showSubProducts, setShowSubProducts] = useState<boolean>(false);
    return (
        <div className="Admin">
            <h1>Administradores</h1>
            <div className="HeaderAdmin">
                <div className="form-check form-switch">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckChecked"
                        onChange={() => setShowSubProducts(!showSubProducts)}
                    ></input>
                    <label
                        className="form-check-label"
                        htmlFor="flexSwitchCheckChecked"
                    >
                        {showSubProducts
                            ? "No mostrar subproductos"
                            : "Mostrar subproductos"}
                    </label>
                </div>
                <Link to={"/admin/new-product"}><button type="button" className="btn btn-light">Nuevo Producto +</button></Link>
            </div>
            <Table showSubProducts={showSubProducts} />
        </div>
    );
}

export default Admin;
