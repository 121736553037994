import React from "react";
import "../pages.scss";
// import "./ComoLlegar.scss";
import { Card } from "react-bootstrap";

function ComoLlegar() {
    return (
        <div className="pages">
            <h1>¿Retiras presencialmente?</h1>
            <iframe
                title="¿Cómo llegar?"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d520.4725457099447!2d-59.44471978599846!3d-34.448670880297705!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bb8d8420b57431%3A0x51c73954c957015!2sLibrer%C3%ADa%20Rayu!5e0!3m2!1ses-419!2sar!4v1721432609431!5m2!1ses-419!2sar"
                width="600"
                height="450"
                // style="border:0;"
                // allowfullscreen=""
                loading="lazy"
                // zoom="8"
                // referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
        </div>
    );
}

export default ComoLlegar;
