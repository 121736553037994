import React from "react";
import { IUser } from "../../../interfaces/User";
import "./Direction.scss";
interface Props{
    user:IUser | undefined
}
function Direction({user}:Props) {
    return (
        <div className="Direction">
            <h3>Dirección</h3>
            <p>
                Dirección:{" "}
                {user?.direction?.address
                    ? user?.direction?.address
                    : "No definió una dirección"}
            </p>
            <p>
                Código Postal:{" "}
                {user?.direction?.CP
                    ? user?.direction?.CP
                    : "No definió un Código postal"}
            </p>
            <p>
                Ciudad:{" "}
                {user?.direction?.city
                    ? user?.direction?.city
                    : "No definió una ciudad"}
            </p>
            <p>
                Provincia:{" "}
                {user?.direction?.prov
                    ? user?.direction?.prov
                    : "No definió una provincia"}
            </p>
        </div>
    );
}

export default Direction;
