import React from 'react'
import Button1 from '../../buttons/Button1/Button1'
import axios from 'axios';
import { useUserContext } from '../../../context/userContext';
import { useCartContext } from '../../../context/cartContext';
import { URL_API } from "../../../usefulFunctions";
import "./Buy.scss";


    
function Buy() {
    const { user } = useUserContext();
    const { cart } = useCartContext();
    const buy = async () => {
        try {
            const response = await axios.post(
                `${URL_API}/api/payments/create-order/${cart?._id}/${user?._id}`
            );
            window.location.href = response.data.init_point;
        } catch (error) {
            console.log(error);
            
        }
    };
  return (
    <div className='Buy'>
        <h1>Continuar compra</h1>
        <p>Vas a comprar los siguientes productos:</p>
        {cart?.products.map(item=><div>{item.quantity} u. de <b>{item.subProduct.IDProduct?.name}</b></div>)}
        <div  onClick={()=>buy()}>
        <Button1 text='Comprar'/>
        </div>
    </div>
  )
}

export default Buy