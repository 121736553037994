import React,{ createContext, useContext, useEffect, useState } from "react";
import { IItem } from "../interfaces/Item";
import { useProductContext } from "./productsContext";

export interface SearchContextType {
    search: string;
    setSearch: (text: string) => void;
    setContext: (text: string) => void;
    filter: () => void;
}
interface Props {
    children: JSX.Element | JSX.Element[];
}

export const SearchContext = createContext<SearchContextType | null>(null);
export const useSearchContext = () => {
    return useContext(SearchContext) as SearchContextType;
};

export const SearchContextProvider = ({ children }: Props) => {
    const [search, setSearch] = useState<string>("");

    const productsContext = useProductContext();
    const { products, updateShowProducts } = productsContext;

    useEffect(() => {
        filter();
    }, [search]);
    // const filter = () => {
    //     const textSearch: string = search.toLowerCase();

    //     let productos: IItem[] = [];
    //     for (const product of products) {
    //         let name = product.name.toLowerCase();
    //         if (name.indexOf(textSearch) !== -1) {
    //             productos.push(product);
    //         }
    //     }

    //     if (!search) {
    //         updateShowProducts({ products });
    //     } else {
    //         updateShowProducts({ products: productos });
    //     }
    // };

    const filter = () => {
        const textSearch: string = search.toLowerCase().trim();
        const words = textSearch.split(' ').filter(word => word);

        let filteredProducts: IItem[] = [];

        if (!search) {
            filteredProducts = products;
        } else {
            filteredProducts = products.filter(product => {
                const name = product.name.toLowerCase();
                return words.every(word => name.includes(word));
            });
        }

        updateShowProducts({ products: filteredProducts });
    };
    const setContext = (text: string) => {
        setSearch(text);
    };

    return (
        <SearchContext.Provider
            value={{
                search,
                setContext,
                setSearch,
                filter,
            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export const updateSearchContext = () => {};
