import React, { useState } from "react";
import Button1 from "../../../buttons/Button1/Button1";
import { IUser } from "../../../../interfaces/User";
import axios from "axios";
import { useUserContext } from "../../../../context/userContext";
interface Props {
    setIsUploadingImg: (value: boolean) => void;
    user: IUser;
}


function EditInfoImgUser({ setIsUploadingImg,user}: Props) {
    const [img, setImg] = useState<File>();
    const urlApi = process.env.REACT_APP_API_URL;
    const {setUser}= useUserContext()

    const sendForm = () => {
        const formData =new FormData();
        formData.append("file-user", img as File);

        const token = localStorage.getItem("authToken");
        const fetchData=async()=>{
            try {
                const newUser=await axios.post(`${urlApi}/api/files/img-user/${user._id}`,formData,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                newUser && setUser(newUser.data)

            } catch (error) {
                console.log(error);
                
            }
        }
        fetchData()
        setIsUploadingImg(false);
    };
    return (
        <div>
            <form>
                <label htmlFor="img">Nueva imagen de perfil</label>
                <input
                    type="file"
                    placeholder="Introduce tu archivo"
                    name="img"
                    id="img"
                    // value={email}
                    onChange={(e) => setImg(e.target.files?.[0])}
                />
                <div onClick={sendForm}>
                    <Button1 text="Guardar información" />
                </div>
            </form>
        </div>
    );
}

export default EditInfoImgUser;
