import React, { useEffect, useState } from "react";
import "./Counter.scss";
import { useCartContext } from "../../../context/cartContext";
interface Props {
    stock: number;
    quantity: number;
    idSubProd: string | undefined;
}
function Counter({ stock, quantity, idSubProd }: Props) {
    const [counter, setCounter] = useState<number>(quantity);
    const { addOrUpdateSubProductToCart, updateInfoCart} = useCartContext();

    const subtractOrAdd = async (operation: string) => {
        try {
                if (operation === "add") {
                    const newQuantity = counter + 1;
                    if (counter < stock) {
                        setCounter(newQuantity);
                    }else{
                        alert(`No hay suficiente stock, por favor ingrese un número menor o igual a ${stock}`);
                    }
                } else if (operation === "subtract") {
                    const newQuantity = counter - 1;
                    if (counter > 1) {
                        setCounter(newQuantity);
                    }else{
                        alert(`Debe ingresar un numero mayor a 1`);
                    }
                }
        } catch (error) {
            console.error(error);
        }
    };
    
    function editQuantity(e: React.ChangeEvent<HTMLInputElement>) {
        const newQuantity = parseInt(e.target.value);
        if (newQuantity < 1) {
            alert(`Debe ingresar un numero mayor a 1`);
        } else if (newQuantity > stock) {
            alert(`No hay suficiente stock, por favor ingrese un número menor o igual a ${stock}`);
        } else {
            setCounter(newQuantity);
        }
    }
    useEffect(()=>{
        addOrUpdateSubProductToCart({
            idSubProduct: idSubProd,
            quantity: counter,
        });
    updateInfoCart()
    },[ counter])

    return (
        <div className="Counter">
            <button
                id="subtractOne"
                className="btnCounter"
                onClick={() => subtractOrAdd("subtract")}
            >
                -
            </button>
            <input
                className="numberCounter"
                id="numberCounter"
                type="number"
                value={counter}
                min="1"
                max={stock}
                onChange={(e) => {
                    editQuantity(e);
                }}
            />
            <button
                id="addOne"
                className="btnCounter"
                onClick={() => subtractOrAdd("add")}
            >
                +
            </button>
        </div>
    );
}

export default Counter;
