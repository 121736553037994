import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import "./NewProduct.scss";
import { Link } from "react-router-dom";
import NewSubProductsContainer from "./NewSubProductsContainer";
import { CompleteProduct, ISize, ISubProduct } from "../../../interfaces/Item";
import axios from "axios";
import { getToken } from "../../../usefulFunctions";
const API_URL = process.env.REACT_APP_API_URL;

function NewProduct() {
    const initialForm: CompleteProduct = {
        name: "",
        description: "",
        price: 0,
        category: "",
        brand: "",
        creationDate: new Date(),
        minimumStock: 0,
        discount: 0,
        size: {
            long: 0,
            width: 0,
            height: 0,
        },
        IDSubProducts: [],
        subProducts: [],
    };
    const [formData, setFormData] = useState<CompleteProduct>(initialForm);
    const [size, setSize] = useState<ISize>({ long: 0, width: 0, height: 0 });
    let [cantSubProducts, setCantSubProduct] = useState<number>(1);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const addSubProduct = (sub: ISubProduct) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            subProducts: [...prevFormData.subProducts, sub],
        }));
    };
    const handleSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const fieldName = name.split(".")[1]; // Obtenemos el nombre del campo de tamaño (long, width, height)
        const newSize = { ...size, [fieldName]: parseInt(value) || 0 }; // Actualizamos el campo correspondiente con el valor convertido a número
        setSize(newSize);
    };
    const handleChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const Submit = async (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log(formData);
        const URL = `${API_URL}/api/products`;
        const token = getToken();
        try {
            const response = await axios.post(URL, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            console.log("Respuesta del servidor:", response.data);
            // .then(()=>alert('Producto creado con exito'))
            // .catch(()=>alert('No se pudo crear el producto'))
            setFormData(initialForm);
            setSubmitted(!submitted);
            alert("Producto agregado con éxito");
        } catch (error) {
            console.log(error);
            alert("No se pudo cargar el producto");
        }
    };
    useEffect(() => {
        setFormData({ ...formData, size });
    }, [size]);
    return (
        <div>
            <h1>Nuevo producto</h1>
            <Link to={"/admin"}>
                <button type="button" className="btn btn-dark">
                    Ir a admin
                </button>
            </Link>

            <form className="NewProduct">
                <div className="productForm">
                    <div className="form-group">
                        <label htmlFor="name">Nombre del producto*</label>
                        <input
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                            type="text"
                            className="form-control"
                            id="name"
                            aria-describedby="emailHelp"
                            placeholder="Nombre del producto*"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="description">Descripción*</label>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={(e) => handleChange(e)}
                            rows={5}
                            className="form-control"
                            id="description"
                            placeholder="Descripción*"
                            required
                        ></textarea>
                    </div>
                    <section>
                        <div className="form-group">
                            <label htmlFor="category">Categoría*</label>
                            <input
                                name="category"
                                value={formData.category}
                                onChange={(e) => handleChange(e)}
                                type="text"
                                className="form-control"
                                id="category"
                                aria-describedby="emailHelp"
                                placeholder="Categoría en PLURAL🙏 y si tiene espacios usa un guion -*"
                                required
                            />
                            <small
                                // id="category"
                                className="form-text text-muted"
                            >
                                PLURAL, si tiene espacios usa un guion -
                            </small>
                        </div>
                        <div className="form-group">
                            <label htmlFor="brand">Marca*</label>
                            <input
                                name="brand"
                                value={formData.brand}
                                onChange={(e) => handleChange(e)}
                                type="text"
                                className="form-control"
                                id="brand"
                                aria-describedby="emailHelp"
                                placeholder="Marca*"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="price">Precio*</label>
                            <input
                                name="price"
                                value={formData.price}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                className="form-control"
                                id="price"
                                aria-describedby="emailHelp"
                                placeholder="Precio*"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="minimumStock">Mínimo</label>
                            <input
                                name="minimumStock"
                                value={formData.minimumStock}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                className="form-control"
                                id="minimumStock"
                                aria-describedby="emailHelp"
                                placeholder="Mínimo"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="discount">Descuento</label>
                            <input
                                name="discount"
                                value={formData.discount}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                className="form-control"
                                id="discount"
                                aria-describedby="emailHelp"
                                placeholder="Descuento"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="size">Medida</label>
                            Largo:
                            <input
                                name="size.long"
                                value={size.long}
                                onChange={(e) => {
                                    handleSizeChange(e);
                                }}
                                type="text"
                                className="form-control"
                                id="size"
                                aria-describedby="emailHelp"
                                placeholder="Medida"
                            />
                            Ancho:
                            <input
                                name="size.width"
                                value={size.width}
                                onChange={(e) => {
                                    handleSizeChange(e);
                                }}
                                type="text"
                                className="form-control"
                                id="size"
                                aria-describedby="emailHelp"
                                placeholder="Medida"
                            />
                            Altura:
                            <input
                                name="size.height"
                                value={size.height}
                                onChange={(e) => {
                                    handleSizeChange(e);
                                }}
                                type="text"
                                className="form-control"
                                id="size"
                                aria-describedby="emailHelp"
                                placeholder="Medida"
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="weight">Peso</label>
                            <input
                                name="weight"
                                value={formData.weight}
                                onChange={(e) => handleChange(e)}
                                type="number"
                                className="form-control"
                                id="weight"
                                aria-describedby="emailHelp"
                                placeholder="Peso"
                            />
                        </div>
                    </section>
                </div>
                <hr />
                <NewSubProductsContainer
                    cantidad={cantSubProducts}
                    addSubProduct={addSubProduct}
                    Submit={Submit}
                    submitted={submitted}
                />
                <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => {
                        cantSubProducts > 1 &&
                            setCantSubProduct(cantSubProducts - 1);
                    }}
                >
                    restar subproducto
                </button>
                <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => setCantSubProduct(cantSubProducts + 1)}
                >
                    Agregar subproducto
                </button>
                <p>Cantidad de subproductos: {cantSubProducts}</p>
                {submitted ? (
                    <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={(e) => Submit(e)}
                        // disabled={submitted ? false : true}
                    >
                        Enviar
                    </button>
                ) : (
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={(e) => {
                            e.preventDefault();
                            setSubmitted(!submitted);
                        }}
                    >
                        Agregar subproductos
                    </button>
                )}
            </form>
        </div>
    );
}

export default NewProduct;
