import React, { ChangeEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CompleteProduct, IItem, ISize } from "../../../interfaces/Item";
import axios from "axios";
import { useUserContext } from "../../../context/userContext";
const API_URL = process.env.REACT_APP_API_URL;
interface Props {
    prod: IItem;
    setEdit: (value: boolean) => void;
}
function EditProductInLine({ prod, setEdit }: Props) {
    // const { idProduct } = useParams();
    const [productEdited, setProductEdited] = useState<IItem>(prod);
    const [size, setSize] = useState<ISize>({
        long: productEdited.size?.long || 0,
        width: productEdited.size?.width || 0,
        height: productEdited.size?.height || 0,
    });

    const [sizeLong, setSizeLong] = useState<number>(prod.size?.long || 0);
    const [sizeWidth, setSizeWidth] = useState<number>(prod.size?.width || 0);
    const [sizeHeight, setSizeHeight] = useState<number>(
        prod.size?.height || 0
    );

    useEffect(() => {
        setProductEdited({
            ...productEdited,
            size: { long: sizeLong, width: sizeWidth, height: sizeHeight },
            lastModifiedDate: new Date(),
        });
    }, [sizeHeight, sizeLong, sizeWidth]);
    const handleSizeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const fieldName = name.split(".")[1]; // Obtenemos el nombre del campo de tamaño (long, width, height)
        const newSize = { ...size, [fieldName]: parseInt(value) || 0 }; // Actualizamos el campo correspondiente con el valor convertido a número
        setSize(newSize);
    };

    const handleChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { value, name } = e.target;
        if (name === "sizeLong") {
            setSizeLong(parseFloat(value));
        } else if (name === "sizeWidth") {
            setSizeWidth(parseFloat(value));
        } else if (name === "sizeHeight") {
            setSizeHeight(parseFloat(value));
        } else {
            setProductEdited({ ...productEdited, [name]: value });
        }
    };
    const { getToken } = useUserContext();
    const update = async () => {
        try {
            const URL = `${API_URL}/api/products/${productEdited._id}`;
            const config = {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            };
            await axios
                .put(URL, productEdited, config)
                .then((data) => console.log("Product updated:", data))
                .then(() => {
                    alert(
                        `Se actualizó el producto ${productEdited.name} con id: ${productEdited._id}`
                    );
                })
                .then(() => setEdit(false));
        } catch (error) {
            console.error("Error updating product:", error);
        }
    };
    useEffect(() => {
        setProductEdited({ ...productEdited, size });
    }, [size]);
    return (
        <tr className="table-primary" key={prod._id}>
            {/* <th scope="row">{prod._id}</th> */}
            <td>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => update()}
                >
                    Guardar
                </button>
            </td>
            <td>
                Nombre:
                <input
                    type="text"
                    value={productEdited.name}
                    name="name"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                <p>
                    <button
                        className="btn btn-secondary"
                        type="button"
                        data-toggle="collapse"
                        data-target={`#collapseEdit${prod._id}`}
                        aria-expanded="false"
                        aria-controls={`collapseEdit${prod._id}`}
                    >
                        Cant de caracteres de la descripción:{" "}
                        {productEdited.description.length}
                    </button>
                </p>
                <div
                    className="collapse"
                    id={`collapseEdit${productEdited._id}`}
                >
                    <div className="card card-body bg-dark">
                        <textarea
                            value={productEdited.description}
                            name="description"
                            onChange={(e) => handleChange(e)}
                        />
                        <button
                            className="btn btn-danger"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapseEdit${productEdited._id}`}
                            aria-expanded="false"
                            aria-controls={`collapseEdit${productEdited._id}`}
                        >
                            Cerrar descripción
                        </button>
                    </div>
                </div>
            </td>
            <td>
                Precio:
                <input
                    type="number"
                    value={productEdited.price}
                    name="price"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                Categoría:
                <input
                    type="text"
                    value={productEdited.category}
                    name="category"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                Marca:
                <input
                    type="text"
                    value={productEdited.brand}
                    name="brand"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                {new Date(productEdited.creationDate).toLocaleDateString(
                    "es-ES",
                    {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    }
                )}
            </td>
            <td>
                {productEdited.lastModifiedDate &&
                    new Date(productEdited.lastModifiedDate).toLocaleDateString(
                        "es-ES",
                        {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                        }
                    )}
            </td>
            <td>
                Largo:
                <input
                    type="text"
                    value={size.long}
                    name="size.long"
                    onChange={(e) => {
                        handleSizeChange(e);
                    }}
                />
                Ancho:
                <input
                    type="text"
                    value={size.width}
                    name="size.width"
                    onChange={(e) => {
                        handleSizeChange(e);
                    }}
                />
                Altura:
                <input
                    type="text"
                    value={size.height}
                    name="size.height"
                    onChange={(e) => {
                        handleSizeChange(e);
                    }}
                />
            </td>
            <td>
                Peso:
                <input
                    type="number"
                    value={productEdited.weight}
                    name="weight"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                Stock mínimo:
                <input
                    type="number"
                    value={productEdited.minimumStock}
                    name="minimumStock"
                    onChange={(e) => handleChange(e)}
                />
            </td>
            <td>
                Descuento:
                <input
                    type="number"
                    value={productEdited.discount}
                    name="discount"
                    onChange={(e) => handleChange(e)}
                />
            </td>
        </tr>
    );
}

export default EditProductInLine;
