import React from 'react'

function Failure() {
  return (
    <div className='pages'>
        <h1>Fallo el pago</h1>
        <p>El pago no fué realizado, inténtalo mas tarde</p>
    </div>
  )
}

export default Failure