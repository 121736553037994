import React from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./ItemCarousel.scss";

export interface PropItemCarousel {
    img: string;
    alt: string;
    link: string;
    title?: string;
    text?: string;
    blank?:boolean
}
function ItemCarrousel({ img, alt, link, title, text,blank }: PropItemCarousel) {
    return (
        <div className="ItemCarousel">
            <Link to={link} target={blank ? "_blank" : "_self"}>
                <img src={img} alt={alt} />
                <Carousel.Caption>
                    {title && <h3>{title}</h3>}
                    {text && <p>{text}</p>}
                </Carousel.Caption>
            </Link>
        </div>
    );
}

export default ItemCarrousel;
