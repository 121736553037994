import React from "react";
import { useContext } from "react";
import "./Search.scss";
import { useNavigate } from "react-router-dom";
import {
    SearchContext,
    SearchContextType,
} from "../../../context/searchContext";

function Search() {
    let navigate = useNavigate();


    const { search, setContext } = useContext(
        SearchContext
    ) as SearchContextType;
    const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setContext(e.target.value);        
        navigate("/catalogo");
    }

    return (
        <input
            type="search"
            className="Search"
            placeholder="¿Qué estas buscando?"
            value={search}
            onChange={(e) => {
                changeSearch(e);
            }
        }
        />
    );
}

export default Search;
