import axios from "axios";
import React, { useEffect, useState } from "react";
import { ISubProduct } from "../../interfaces/Item";
import CarouselImages from "../ItemDetail/CarouselImages/CarouselImages";
import { AiFillDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { GrAddCircle } from "react-icons/gr";
import EditSubProductInLine from "./Edit/EditSubProductInLine";
const API_URL = process.env.REACT_APP_API_URL

interface Props {
    id: string | undefined;
}
function SubProductAdmin({ id }: Props) {
    const [subProd, setSubProd] = useState<ISubProduct>();
    const [edit, setEdit] = useState<boolean>(false);
    useEffect(() => {
        const getSubproducts = async () => {
            if (id) {
                await axios
                    .get(`${API_URL}/api/subproducts/${id}`)
                    .then((data) => setSubProd(data.data))
                    .catch((error) => console.log(error));
            }
        };
        getSubproducts();
    }, [id]);
    return subProd ? (
        <>
            <tr className="table-white" key={subProd._id}>
                <td>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setEdit(!edit)}
                    >
                        <CiEdit />
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        // onClick={() => setTryDelete(true)}
                    >
                        <AiFillDelete />
                    </button>
                </td>
                <td>Color: {subProd?.color}</td>
                <td>Stock: {subProd.stock}</td>
                <td>Código de barra: {subProd.barcode}</td>
                <td>
                    <CarouselImages images={subProd.img} />
                </td>
                <td>
                    {new Date(subProd.creationDate).toLocaleDateString("es-ES", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
                </td>
                <td>
                    {subProd.lastModifiedDate
                        ? new Date(subProd.lastModifiedDate).toLocaleDateString(
                            "es-ES",
                            {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                            }
                        )
                        : "todavía no fué modificado"}
                </td>
            </tr>
            {edit && <EditSubProductInLine subProd={subProd} setEdit={setEdit}/>}
        </>
    ) : (
        <></>
    );
}

export default SubProductAdmin;
