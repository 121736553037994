import React, { useState, useEffect } from "react";
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from "@hello-pangea/dnd";

interface IEditUrlImgs {
    arraysUrlImgs: string[];
    onUrlsChange: (urls: string[]) => void;
}

function EditUrlImgs({ arraysUrlImgs, onUrlsChange }: IEditUrlImgs) {
    const [urlsImg, setUrlsImg] = useState<string[]>([]);

    // Actualiza el estado cuando cambian las propiedades
    useEffect(() => {
        setUrlsImg(arraysUrlImgs);
    }, [arraysUrlImgs]);

    const handleOnDragEnd = (result: DropResult) => {
        const { source, destination } = result;

        // Si no hay destino, salir
        if (!destination) return;

        // Reordenar la lista
        const items = Array.from(urlsImg);
        const [reorderedItem] = items.splice(source.index, 1);
        items.splice(destination.index, 0, reorderedItem);

        setUrlsImg(items);
        onUrlsChange(items);
    };

    const handleInputChange = (index: number, newValue: string) => {
        const updatedUrls = [...urlsImg];
        updatedUrls[index] = newValue;
        setUrlsImg(updatedUrls);
        onUrlsChange(updatedUrls);
    };

    const addNewItem = () => {
        const newUrl = `NewItem${urlsImg.length + 1}`;
        const updatedUrls = [...urlsImg, newUrl];
        setUrlsImg(updatedUrls);
        onUrlsChange(updatedUrls);
    };

    const removeItem = (index: number) => {
        const updatedUrls = urlsImg.filter((_, i) => i !== index);
        setUrlsImg(updatedUrls);
        onUrlsChange(updatedUrls);
    };

    return (
        <div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="droppableImgUrl">
                    {(provided) => (
                        <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ listStyleType: "none", padding: 0 }}
                        >
                            {urlsImg.map((url, index) => (
                                <Draggable
                                    key={url}
                                    draggableId={url}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                userSelect: "none",
                                                padding: 16,
                                                margin: "0 0 8px 0",
                                                minHeight: "50px",
                                                backgroundColor:
                                                    snapshot.isDragging
                                                        ? "#263B4A"
                                                        : "#456C86",
                                                color: "white",
                                                display: "flex",
                                                alignItems: "center",
                                                ...provided.draggableProps
                                                    .style,
                                            }}
                                        >
                                            <img
                                                src={url}
                                                alt={url}
                                                style={{
                                                    maxWidth: "7rem",
                                                    maxHeight: "7rem",
                                                    marginRight: "8px",
                                                }}
                                            />
                                            <input
                                                type="text"
                                                value={url}
                                                onChange={(e) =>
                                                    handleInputChange(
                                                        index,
                                                        e.target.value
                                                    )
                                                }
                                                style={{ flex: 1 }}
                                            />
                                            <button
                                                onClick={() =>
                                                    removeItem(index)
                                                }
                                                style={{ marginLeft: "8px" }}
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
            <button onClick={addNewItem}>Add New Item</button>
        </div>
    );
}

export default EditUrlImgs;
