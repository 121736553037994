import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useUserContext } from "../../../context/userContext";

function ResponseGoogle() {
    const { token } = useParams();
    const { updateInfoUser,saveTokenToLocalStorage } = useUserContext();
    useEffect(() => {
        if (token) {
            saveTokenToLocalStorage(token)
            updateInfoUser(token)
        }
    }, [token]);
    return <div>
        ResponseGoogle se guardo el token: {token}
    </div>;
}

export default ResponseGoogle;
