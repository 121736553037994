import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const RedirectComponent = () => {
    const [ios, setIos] = useState(false);
    const userAgent = navigator.userAgent || "";
    useEffect(() => {
        function isInAppBrowser() {
            const userAgent = navigator.userAgent || "";
            return /Instagram|FBAN|FBAV|Twitter/i.test(userAgent);
        }

        function isIOS() {
            const data = /iPhone|iPad|iPod/i.test(navigator.userAgent);
            if (data) {
                setIos(true);
            }
            return /iPhone|iPad|iPod/i.test(navigator.userAgent);
        }

        // Redirigir a la URL principal
        const redirectToUrl = () => {
            window.location.href = "https://server.rayu.com.ar/api/redirect"; ;
            // window.open("https://rayu.com.ar", "_blank");
        };

        // Intentar redirigir al navegador nativo
        if (isInAppBrowser()) {
            if (isIOS()) {
                // Para iOS, redirigimos a la URL principal. Safari debería abrir esta URL.
                setTimeout(redirectToUrl, 100); 
            } else {
                // En Android, intentamos abrir Chrome.
                window.location.href =
                    "intent://rayu.com.ar#Intent;scheme=https;package=com.android.chrome;end";
            }
        } else {
            // Si no estamos en un navegador in-app, redirigimos normalmente.
            redirectToUrl();
        }
    }, []);

    return (
        <div>
            <p>Redirigiendo...</p>
            <p>
                Estamos redirigiéndote a un navegador nativo como Safari o
                Chrome para que puedas acceder a todas las funciones.
            </p>
            <p>Como por ejemplo, iniciar sesión con Google.</p>
            <p>
                Si no fuiste redirigido correctamente, te recomendamos abrir el
                sitio en tu navegador favorito de manera manual.
            </p>
            Si no fuiste redirigido correctamente, <a href="https://rayu.com.ar" target="_blank" rel="noopener noreferrer">haz clic aquí</a> para abrir el sitio en tu navegador.

            
            <Link to={"/"} className="logo">
                <img
                    style={{ width: "30vw" }}
                    className="logo"
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="Logo de Rayuela"
                />
            </Link>
        </div>
    );
};

export default RedirectComponent;
