import React from 'react'
import { useProductContext } from './context/productsContext'

function VerProductos() {
    const{products}=useProductContext()
  return (
    <div>VerProductos
        <p>{JSON.stringify(products)}</p>
    </div>
  )
}

export default VerProductos