import React from "react";
import ItemCarousel, { PropItemCarousel } from "./ItemCarousel";
import { Carousel } from "react-bootstrap";
import  "./Carousel.scss"

function CarouselIndex() {
    const carouselCarouselData: PropItemCarousel[] = [
        {
            img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/carousel%2FMesa%20de%20trabajo%201.png?alt=media&token=f4c775b3-1f0a-4859-b1c9-310d8da0af6d",
            alt: "Imagen Vuelta a clases",
            link: "/catalogo/categoria/Mochilas",
        },
        {
            img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/carousel%2Fseguinos%20en%20ig.png?alt=media&token=26ae74f9-ac42-4b7d-9be2-ea8676b31c0f",
            alt: "Imagen seguinos en instagram",
            link: "https://www.instagram.com/rayu_ar/",
            blank:true
        },
        {
            img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/carousel%2Fcarrusel3.jpg?alt=media&token=d0897d68-6861-4dfc-8ce0-ffa596917176",
            alt: "Imagen Rayu slogan",
            link: "/catalogo"
        },
    ];
    return (
        <div>
                <Carousel>
                    {carouselCarouselData.map((item) => (
                        // <p>{JSON.stringify({...item})}</p>
                        <Carousel.Item key={item.img}>
                            <ItemCarousel {...item} key={item.link}/>
                        </Carousel.Item>
                    ))}
                </Carousel>

        </div>
    );
}

export default CarouselIndex;
