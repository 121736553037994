import React, { useEffect, useState } from "react";
import { IOrder } from "../../../interfaces/Orders";
import SimpleTable from "../../SimpleTable/SimpleTable";
import { URL_API, getToken } from "../../../usefulFunctions";
import axios from "axios";
import dayjs from "dayjs";
import Select1 from "../../buttons/Select1/Select1";
import { ICompleteSubProductToCart } from "../../../interfaces/Item";
import { Accordion } from "react-bootstrap";
import Button1 from "../../buttons/Button1/Button1";
import { Link } from "react-router-dom";

function OrdersContainer() {
    const [orders, setOrders] = useState<IOrder[]>();
    const [state, setState] = useState<
        "todas" | "Orden-creada" | "En-preparación" | "En-camino" | "recibida"
    >("En-preparación");

    useEffect(() => {
        try {
            const token = getToken();

            const config = {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            };
            if (state === "todas") {
                const fetchData = async () => {
                    const data = await axios.get(`${URL_API}/api/orders`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setOrders(data.data);
                };
                fetchData();
            } else {
                const fetchData = async () => {
                    const data = await axios.get(
                        `${URL_API}/api/orders/state/${state}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    );
                    setOrders(data.data);
                };
                fetchData();
            }
        } catch (error) {
            console.log(error);
        }
    }, [state]);

    const columns = [
        {
            header: "EDITAR estado",
            cell: (row: any) => {
                const order = row.row.original;
                return <Link to={`/admin/edit-state/${order._id}`}>
                <Button1 text="✏️" />
                </Link>
            },
            footer: "EDITAR estado",
        },
        {
            header: "STATE",
            accessorKey: "state",
            footer: "STATE",
        },
        {
            header: "USUARIO Nombre",
            accessorKey: "userId.name",
            footer: "USUARIO Nombre",
        },
        {
            header: "USUARIO Apellido",
            accessorKey: "userId.lastName",
            footer: "USUARIO Apellido",
        },
        {
            header: "USUARIO Mail",
            accessorKey: "userId.email",
            footer: "USUARIO Mail",
        },
        {
            header: "USUARIO Teléfono",
            accessorKey: "userId.phone",
            footer: "USUARIO Teléfono",
        },
        {
            header: "DIRECCIÓN Provincia",
            accessorKey: "userId.direction.prov",
            footer: "DIRECCIÓN Provincia",
        },
        {
            header: "DIRECCIÓN Ciudad",
            accessorKey: "userId.direction.city",
            footer: "DIRECCIÓN Ciudad",
        },
        {
            header: "DIRECCIÓN CP",
            accessorKey: "userId.direction.CP",
            footer: "DIRECCIÓN CP",
        },
        {
            header: "DIRECCIÓN address",
            accessorKey: "userId.direction.address",
            footer: "DIRECCIÓN address",
        },

        {
            header: "Productos",
            accessorKey: "cartProducts",
            cell: (row: any) => {
                const data = row.row.original.cartProducts;
                // const products = row || {};
                return (
                    <>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    Ver los prods
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {data.map(
                                            (
                                                product: ICompleteSubProductToCart,
                                                index: any
                                            ) => (
                                                <li key={index}>
                                                    <b>{product.quantity}</b>{" "}
                                                    unidades de{" "}
                                                    <b>
                                                        {
                                                            product.subProduct
                                                                .IDProduct?.name
                                                        }
                                                    </b>
                                                    , color{" "}
                                                    {product.subProduct.color}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                );
            },
            footer: "Productos",
        },
        {
            header: "Fecha de creación",
            accessorKey: "creationDate",
            cell: (row: any) => {
                const date = row.row.original.creationDate;
                return `${dayjs(date).format("DD/MM/YYYY HH:MM")} Hs`;
            },
            footer: "Fecha de creación",
        },
        {
            header: "Precio de envío",
            accessorKey: "priceShipment",
            footer: "Precio de envío",
        },
        {
            header: "Precio total de products",
            accessorKey: "totalPriceOfProducts",
            footer: "Precio total de products",
        },
        {
            header: "Precio total",
            accessorKey: "totalPrice",
            footer: "Precio total",
        },
    ];
    const arraySelect = [
        { text: "todas", value: "todas" },
        { text: "Orden-creada", value: "Orden-creada" },
        { text: "En-preparación", value: "En-preparación" },
        { text: "En-camino", value: "En-camino" },
        { text: "recibida", value: "recibida" },
    ];
    return (
        <div>
            <h1>Ordenes</h1>
            <Select1
                array={arraySelect}
                setState={setState}
                state={state}
                title="Mostrar por estado"
            />
            {orders && (
                <>
                    el estado es: {state} hay {orders.length} ordenes con este estado
                    <SimpleTable orders={orders} columns={columns} />
                </>
            )}
        </div>
    );
}

export default OrdersContainer;
