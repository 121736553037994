//Eliminar duplicados de un array
export const deleteDuplicated = (array: Array<any>) => {
    return array.filter((valor, indice, array) => {
        return array.indexOf(valor) === indice;
    });
};

export const orderAlphabetically = (array: Array<string>)=>{
    return array.sort(function(a, b) {
        return a.toLowerCase().localeCompare(b.toLowerCase());
    });
}
export const orderAlphabeticallyDescending = (array: Array<string>)=>{
    return array.sort(function(a, b) {
        return b.toLowerCase().localeCompare(a.toLowerCase());
    });
}

export const getToken = ()=>{
    const token = localStorage.getItem("authToken") || undefined;
    return token
}

export const URL_API= process.env.REACT_APP_API_URL;
