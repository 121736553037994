import axios from "axios";
import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { getToken } from "../../../../usefulFunctions";

interface Prods {
    idSubProduct: string | undefined;
}
function AddImgsSubProducts({ idSubProduct }: Prods) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [imgs, setImgs] = useState<File[]>();

    const API_URL = process.env.REACT_APP_API_URL;
    const sendForm = async () => {
        try {
            const URL = `${API_URL}/api/files/imgs-subProduct/${idSubProduct}`;
            const formData = new FormData();
            if (imgs) {
                imgs.forEach((file, index) => {
                    formData.append("files-img-subProduct", file);
                });
            }
            console.log(formData,'formData');
            
            await axios
                .post(URL, formData, {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((data) =>
                    console.log("SubProduct img was updated:", data)
                )
                .then(() => {
                    alert(
                        `Se actualizaron las imagenes de el subproducto con id: ${idSubProduct}`
                    );
                });
            // .then(() => setEdit(false));
        } catch (error) {
            console.error("Error updating product:", error);
        }
    };
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Agregar imgs desde archivos
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Carga tus archivos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <input
                            type="file"
                            placeholder="Introduce tu archivo"
                            name="img"
                            id="img"
                            multiple
                            onChange={(e) => setImgs([...(e.target.files || [])])}
                        />
                        <br/>
                        <br/>
                        <br/>
                        <Button
                            variant="primary"
                            onClick={() => {
                                sendForm();
                                handleClose();
                            }}
                        >
                            Guardar cambios
                        </Button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddImgsSubProducts;
