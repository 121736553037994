import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSearchContext } from '../../../context/searchContext'
interface Props{
    changeBurgerMenu:()=>void
}
function Menu({changeBurgerMenu}:Props) {
    const {setSearch} = useSearchContext()
    return (
        <nav>
            <ul>
                <li><NavLink end to={'/ofertas'} onClick={()=>changeBurgerMenu()}>Ofertas</NavLink></li>
                <li><NavLink end to={'/catalogo'} onClick={()=>{changeBurgerMenu();setSearch('')}}>Catálogo</NavLink></li>
                <li><NavLink end to={'/ideas-creativas'} onClick={()=>changeBurgerMenu()}>Ideas Creativas</NavLink></li>
            </ul>
        </nav>
    )
}

export default Menu