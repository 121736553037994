import React from "react";
import "./Counter.scss";
interface Props {
    stock: number;
    quantity: number;
    updateQuantity: (number: number) => void;
}
function Counter({ stock, quantity, updateQuantity }: Props) {
    // const [quantity, updateQuantity] = useState<number>(1);
    function subtractOrAdd(operation: string) {
        if (operation === "add") {
            const newQuantity = quantity + 1;
            quantity < stock
                ? updateQuantity(newQuantity)
                : // updateQuantity(newQuantity)
                  alert(
                      `No hay suficiente stock, por favor ingrese un número menor o igual a ${stock}`
                  );
        } else if (operation === "subtract") {
            const newQuantity = quantity - 1;
            quantity > 1
                ? // updateQuantity(newQuantity)
                  updateQuantity(newQuantity)
                : alert("No puede ingresar un numero menor a 1");
        }
    }
    function editQuantity(e: React.ChangeEvent<HTMLInputElement>) {
        const newQuantity = parseInt(e.target.value);
        if (newQuantity < 1) {
            alert("Debe ingresar un numero mayor a 1");
        } else if (newQuantity > stock) {
            alert(
                `No hay suficiente stock, por favor ingrese un número menor o igual a ${stock}`
            );
        } else {
            updateQuantity(newQuantity);
        }
    }
    return (
        <div className="Counter">
            <button
                id="subtractOne"
                className="btnCounter"
                onClick={() => subtractOrAdd("subtract")}
            >
                -
            </button>
            <input
                className="numberCounter"
                id="numberCounter"
                type="number"
                value={quantity}
                min="1"
                max={stock}
                onChange={(e) => {
                    editQuantity(e);
                }}
            />
            <button
                id="addOne"
                className="btnCounter"
                onClick={() => subtractOrAdd("add")}
            >
                +
            </button>
        </div>
    );
}

export default Counter;
