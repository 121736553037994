import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IUser } from "../../interfaces/User";
import "./Header.scss";
import Menu from "./Navbar/Menu";
import Search from "./Search/Search";
import AsideUser from "./AsideUser/AsideUser";
import { useUserContext } from "../../context/userContext";
import { useCartContext } from "../../context/cartContext";
import { OffCanvasUser } from "./OffCanvasUser/OffCanvasUser";

function Header() {
    const { user } = useUserContext();
    const { quantityInCart } = useCartContext();
    const [burgerMenu, setBurgerMenu] = useState(true);
    const changeBurgerMenu = () => {
        setBurgerMenu(!burgerMenu);
    };
    return (
        <header>
            <Link to={"/"} className="logo">
                <img
                    className="logo"
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="Logo de Rayuela"
                />
            </Link>
            <div className={`menu ${!burgerMenu && "menuActive"}`}>
                <Menu changeBurgerMenu={changeBurgerMenu} />
            </div>

            <div className="icons">
                {/* <OffCanvasUser name="end" user={user}/> */}
                <AsideUser user={user} />
                <div className="cartIcon">
                    <Link to={"/carrito"} className="cartIcon">
                        {quantityInCart !==0 && <div className="quantityInCart">{quantityInCart}</div>}
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/icons/cart-white.svg"
                            }
                            alt="Logo de Rayuela"
                        />
                    </Link>
                </div>
            </div>
            <div className={`search ${!burgerMenu && "searchActive"}`}>
                <Search />
            </div>
            <div className="burgerButton">
                {burgerMenu ? (
                    <button
                        type="button"
                        className="burgerMenu"
                        onClick={() => changeBurgerMenu()}
                    >
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/icons/burger-menu.svg"
                            }
                            alt="Menu burger"
                        />
                    </button>
                ) : (
                    <div className="burgerMenu">
                        <button
                            type="button"
                            className="burgerMenu"
                            onClick={() => changeBurgerMenu()}
                        >
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/images/icons/x-burger-menu.svg"
                                }
                                alt="Close menu burger"
                            />
                        </button>
                    </div>
                )}
            </div>
        </header>
    );
}

export default Header;
