import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IItem, ISubProduct } from "../../interfaces/Item";
import Button1 from "../buttons/Button1/Button1";
import Counter from "../buttons/Counter/Counter";
import SelectColorContainer from "../buttons/SelectColor/SelectColorContainer";
import CarouselImages from "./CarouselImages/CarouselImages";
import "./ItemDetail.scss";
import axios from "axios";
import { useCartContext } from "../../context/cartContext";
import { thereIsStockInThisSub } from "../Item/function";

interface Props {
    product: IItem;
    subProduct: ISubProduct;
    subProducts: ISubProduct[];
    changeSubProduct: (IDSubProduct: string) => void;
}
function ItemDetail({
    product,
    subProduct,
    subProducts,
    changeSubProduct,
}: Props) {
    const {cart, addOrUpdateSubProductToCart } = useCartContext();
    const [quantity, setQuantity] = useState<number>(1);
    const [stock, setStock] = useState(false);

    const navigate = useNavigate()
    const updateQuantity = (number: number) => {
        setQuantity(number);
    };
    useEffect(() => {
        thereIsStockInThisSub({ subProduct, setStock });
    }, [subProduct]);
    return (
        <div>
            <div className={`itemDetail ${!stock && "without-Stock-itemDetail"}`}>
                {/* {stock ? "hay stock" : "no hay stock"} */}
                <h1 className="title">{product.name}</h1>
                <div className="imgProducts">
                    {subProduct && <CarouselImages images={subProduct.img[0] ?subProduct.img: [process.env.PUBLIC_URL + "/images/logo.png"]} />}
                </div>
                <div className="price">${product.price}</div>
                <div className="selectColor">
                    <SelectColorContainer
                        subProducts={subProducts}
                        changeSubProduct={changeSubProduct}
                    />
                </div>
                {stock ? (
                    <>
                        <div className="btnQuantity">
                            {subProduct && (
                                <Counter
                                    stock={subProduct.stock}
                                    quantity={quantity}
                                    updateQuantity={updateQuantity}
                                />
                            )}
                        </div>
                        <div className="totalPrice">totalPrice</div>
                        <div
                            className="btnAddToCart"
                            onClick={() =>
                                cart?
                                addOrUpdateSubProductToCart({
                                    idSubProduct: subProduct._id,
                                    quantity,
                                })
                                :
                                <>
                                {alert(`Debes iniciar sesión para poder agregar productos al carrito`)}
                                {navigate('/login')}
                                </>
                            }
                        >
                            <Button1 text="Añadir al carrito" />
                        </div>
                    </>
                ):<div className="without-stock-message">
                    {subProducts[1]?
                    'No hay stock de este color, elije otro'
                    :
                    'No hay stock'
                    }
                </div>}
                <div className="btnReturnToCatalog">
                    <Link to="/catalogo">
                        <Button1 text="Volver al catálogo" />
                    </Link>
                </div>
                <div className="description">{product.description}</div>
            </div>
        </div>
    );
}

export default ItemDetail;
