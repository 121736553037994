import React from 'react'
import './Button2.scss'
type Props = {
    text:string
}
function Button2({text}:Props) {
    return (
        <button className='Button2'>
            {text}
        </button>
    )
}

export default Button2