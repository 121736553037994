import React from "react";
import { ICompleteSubProductToCart } from "../../interfaces/Item";
import "./ItemCart.scss";
import Button2 from "../buttons/Button2/Button2";
import CounterCart from "../buttons/Counter/CounterCart";
import { useCartContext } from "../../context/cartContext";
import { Link } from "react-router-dom";
interface Props {
    prod: ICompleteSubProductToCart;
}
function ItemCart({ prod }: Props) {
    const { deleteProductOfCart } = useCartContext();
    return (
        // <>lala</>
        prod?.subProduct?.IDProduct ? (
            <div key={prod.subProduct._id} className="ItemCart">
                <div className="name_ItemCart">
                    <Link to={`/detalle/${prod.subProduct.IDProduct._id}`}>
                        {prod.subProduct.IDProduct.name}
                    </Link>
                </div>
                <div className="img_ItemCart">
                    <Link to={`/detalle/${prod.subProduct.IDProduct._id}`}>
                        <img
                            src={prod.subProduct.img[0]}
                            alt={`${prod.subProduct.IDProduct.name} color ${prod.subProduct.color}`}
                        />
                    </Link>
                </div>
                <div className="color_ItemCart">
                    Color: {prod.subProduct.color}
                </div>
                <div className="quantity_ItemCart">
                    <CounterCart
                        stock={prod.subProduct.stock}
                        quantity={prod.quantity}
                        idSubProd={prod._id}
                    />
                </div>
                <div className="price_ItemCart">
                    <b>${prod.subProduct.IDProduct.price}</b>
                </div>
                <div className="totalPrice_ItemCart">
                    precio total
                    <b>
                        $
                        {(
                            prod.subProduct.IDProduct.price * prod.quantity
                        ).toFixed()}
                    </b>
                </div>
                <div
                    className="delete_ItemCart"
                    onClick={() => prod._id && deleteProductOfCart(prod._id)}
                >
                    <Button2 text="Borrar producto" /* onClick={alert()} */ />
                </div>
            </div>
        ) : (
            <></>
        )
    );
}

export default ItemCart;
