import React from "react";
import "./App.scss";
import MainProducts from "./components/MainProducts/MainProducts";
import Footer from "./components/Footer/Footer";
import {
    Route,
    BrowserRouter as Router,
    Routes,
    Navigate,
} from "react-router-dom";
import Header from "./components/Header/Header";
import ItemDetailContainer from "./components/ItemDetail/ItemDetailContainer";
import "bootstrap/dist/css/bootstrap.min.css";
import CartContainer from "./components/Cart/CartContainer";
import Error404 from "./components/Error404/Error404";
import Admin from "./components/Admin/Admin";
import NewProduct from "./components/Admin/NewProduct/NewProduct";
import Register from "./components/Acount/Register/Register";
import Login from "./components/Acount/Login/Login";
import { useUserContext } from "./context/userContext";
import GoTop from "./components/buttons/GoTop/GoTop";
import InfoUser from "./components/Acount/InfoUser/InfoUser";
import ResponseGoogle from "./components/Acount/LoginGoogleButton/ResponseGoogle";
import Orders from "./components/Orders/Orders";
import Index from "./components/Index/Index";
import VerProductos from "./VerProductos";
import Buy from "./components/Orders/Buy/Buy";
import OrdersContainer from "./components/Admin/Orders/OrdersContainer";
import MyOrders from "./components/MyOrders/MyOrders";
import SobreNosotros from "./components/pages/SobreNosotros/SobreNosotros";
import FormasDePago from "./components/pages/FormasDePagos/FormasDePago";
import ComoLlegar from "./components/pages/ComoLlegar/ComoLlegar";
import AddMoreSubProducts from "./components/Admin/AddMoreSubProduct/AddMoreSubProducts";
import Success from "./components/Orders/BackUrls/Success";
import Failure from "./components/Orders/BackUrls/Failure";
import Pending from "./components/Orders/BackUrls/Pending";
import EditState from "./components/Admin/Orders/EditState";
import EditUrlImgs from "./components/Admin/Edit/EditUrlImgs/EditUrlImgs";
import Ofertas from "./components/pages/Ofertas/Ofertas";
import IdeasCreativas from "./components/pages/IdeasCreativas/IdeasCreativas";
import Redirect from "./components/Redirect/Redirect";
import RedirectPrueba from "./components/Redirect/RedirectPrueba";

function App() {
    const { user } = useUserContext();
    const isAdmin = () => {
        if (user?.rol === "admin") {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div className="App">
            {/* <Router> */}
                <Header />
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route
                        path="/catalogo/categoria/:category"
                        element={<MainProducts />}
                    />
                    <Route
                        path="/catalogo/marca/:brand"
                        element={<MainProducts />}
                    />
                    <Route path="/catalogo" element={<MainProducts />} />

                    <Route path="/ofertas" element={<Ofertas/>} />
                    <Route path="/ideas-creativas" element={<IdeasCreativas/>} /> 
                    <Route
                        path="/sobre-nosotros"
                        element={<SobreNosotros/>}
                    />
                    <Route
                        path="/formasDePago"
                        element={<FormasDePago/>}
                    />
                    <Route
                        path="/comoLlegar"
                        element={<ComoLlegar/>}
                    />
                    <Route path="/Contacto" element={<div>Contacto</div>} />
                    <Route
                        path="/detalle/:idProduct"
                        element={<ItemDetailContainer />}
                    />
                    {/* Comptas */}
                    <Route path="/carrito" element={<CartContainer />} />
                    <Route path="/order" element={<Orders />} />
                    <Route path="/buy" element={<Buy />} />

                    <Route path="/success" element={<Success />} />
                    <Route path="/failure" element={<Failure />} />
                    <Route path="/pending" element={<Pending />} />

                    {/* Manejo de sesiones y datos de usuario */}

                    <Route
                        path="/login"
                        element={user ? <Navigate to="/" /> : <Login />}
                    />
                    <Route
                        path="/loginGoogle/:token"
                        element={
                            user ? <Navigate to="/" /> : <ResponseGoogle />
                        }
                    />
                    <Route
                        path="/info-user"
                        element={user ? <InfoUser /> : <Navigate to="/" />}
                    />
                    <Route
                        path="/mis-pedidos"
                        element={user ? <MyOrders /> : <Navigate to="/" />}
                    />
                    <Route
                        path="/register"
                        element={user ? <Navigate to="/" /> : <Register />}
                    />

                    {/* Administradores */}
                    <Route
                        path="/admin"
                        element={isAdmin() ? <Admin /> : <Navigate to="/" />}
                    />
                    <Route
                        path="/admin/add-more-sub-product/:idProduct"
                        element={
                            isAdmin() ? <AddMoreSubProducts /> : <Navigate to="/" />
                        }
                    />
                    <Route
                        path="/admin/new-product"
                        element={
                            isAdmin() ? <NewProduct /> : <Navigate to="/" />
                        }
                    />
                    <Route
                        path="/admin/order"
                        element={
                            isAdmin() ? <OrdersContainer /> : <Navigate to="/" />
                        }
                    />
                    <Route
                        path="/admin/edit-state/:idOrder"
                        element={
                            isAdmin() ? <EditState /> : <Navigate to="/" />
                        }
                    />
                    <Route
                        path="/ver-productos"
                        element={
                            <VerProductos/>
                        }
                    />
                    <Route
                        path="/redirect"
                        element={
                            <Redirect/>
                        }
                    />
                    <Route
                        path="/redirect-prueba"
                        element={
                            <RedirectPrueba/>
                        }
                    />
                    {/* <Route
                        path="/prueba-imgs-url"
                        element={
                            // <EditUrlImgs/>
                        }
                    /> */}

                    <Route path="*" element={<Error404 />} />
                </Routes>
                <Footer />
            {/* </Router> */}
            <GoTop />
        </div>
    );
}

export default App;
