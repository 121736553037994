import React from "react";
import "./CategoriesIndex.scss";
import Category from "./Category";

const listCategoriesIndex: { category: string; img: string }[] = [
    {
        category: "Mochilas",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Fmochilas.jpg?alt=media&token=11291ea7-8006-4811-890f-5366f839e533",
    },
    {
        category: "Pinceles",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Fpinceles.jpg?alt=media&token=b4cc4b7d-75c1-4c79-9a61-3de38869881c",
    },
    {
        category: "Tijeras",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Ftijeras.jpg?alt=media&token=d80307ee-eaba-48f7-aa7f-d656d899a55a",
    },
    {
        category: "Acrílicos",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Facrilicos.jpg?alt=media&token=59825f10-bc5e-4d42-826a-18431aa382e0",
    },
    {
        category: "Carpetas",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Fcarpetas.jpg?alt=media&token=36fe01ea-7293-4f8d-ad6f-9c9a8a016426",
    },
    {
        category: "Cuadernos",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Fcuadernos.jpg?alt=media&token=31f4952f-259c-4707-a703-246c1d31e838",
    },
    {
        category: "Lapiceras",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Flapiceras.jpg?alt=media&token=c8fdaf40-1065-4eae-833e-317488ad4261",
    },
    {
        category: "Libros",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Flibros.jpg?alt=media&token=3c16e13b-3180-4af3-b875-79e5b8023c99",
    },
    {
        category: "Adhesivos",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Fpegamentos.jpg?alt=media&token=db7c962d-57f3-4c06-8f1d-28f0cc69ca08",
    },
    {
        category: "Agendas",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Fagendas2024.jpg?alt=media&token=7e159129-048a-4ee9-a00b-ac2f6ca92b44",
    },
    {
        category: "Lápices",
        img: "https://firebasestorage.googleapis.com/v0/b/rayu-411121.appspot.com/o/categoriesIndex%2Flapices-de-colores.jpg?alt=media&token=568f76b8-5018-4aab-9649-0ea69f5df562",
    },
];
function CategoriesIndex() {
    return (
        <>
            <h1>Algunas de nuestras categorías</h1>
            <div className="CategoriesIndex">
                {listCategoriesIndex.map((individualCategory) => (
                    <Category {...individualCategory}  key={individualCategory.category}/>
                ))}
            </div>
        </>
    );
}

export default CategoriesIndex;
