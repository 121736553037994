import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./GoCatalogue.scss";
import { hasTouch } from 'detect-touch';

function GoCatalogue() {
    const navigate =useNavigate()
    const goTo=(text:string)=>{
        if(hasTouch){
            setTimeout(()=>{

                navigate(`/${text}`)
            },600)
        }else{
            navigate(`/${text}`)
        }
    }
    return (
        <div className="GoCatalogue">
                {/* <Link to={`/catalogo`}> */}
                <div className="cardCatalogue" onClick={()=>goTo('catalogo')}>
                    <div className="imgBx"></div>
                    <div className="contentBx"></div>
                </div>
        {/* </Link> */}
            </div>
    );
}

export default GoCatalogue;
