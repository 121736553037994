import { ISubProduct } from "../../interfaces/Item";

// Función para calcular los metros cuadrados del paquete 
export function calcularMetrosCuadradosDelPaquete(productos:any[]) {
    let metrosCuadradosTotal = 0;

    productos.forEach(producto => {
        metrosCuadradosTotal += producto.alto * producto.ancho * producto.largo;
    });

    return metrosCuadradosTotal;
}

// Ejemplo de uso
const productos = [
    { alto: 10, ancho: 5, largo: 15, peso: 2 },
    { alto: 8, ancho: 4, largo: 12, peso: 1.5 },
    { alto: 6, ancho: 3, largo: 10, peso: 1 }
];

const metrosCuadradosDelPaquete = calcularMetrosCuadradosDelPaquete(productos);
console.log("Metros cuadrados del paquete:", metrosCuadradosDelPaquete);
