import React from "react";
import "./DeleteProduct.scss";
import axios from "axios";
import { IItem } from "../../../interfaces/Item";
import { useState } from "react";
import { getToken } from "../../../usefulFunctions";
const API_URL = process.env.REACT_APP_API_URL
interface Props {
    prod: IItem;
    setTryDelete: (value: boolean) => void;
}
function DeleteProduct({ prod, setTryDelete }: Props) {
    const [message, setMessage] = useState<string>("");
    const deleteProduct = (id: string | undefined) => {
        const URL = `${API_URL}/api/products`;
        const token = getToken()
        try {
            id &&
                axios
                    .delete(`${URL}/${id}`,{
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    // .then(() => setTryDelete(false))
                    .then(() => setMessage(`El producto ${id} fue eliminado`));
        } catch (error) {
            
            alert(`No se pudo borrar el prod: ${id}`);
        }
    };
    return (
        <div className="DeleteProduct alert alert-dismissible alert-danger">
            <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                onClick={() => setTryDelete(false)}
            ></button>
            {message ? (
                <>
                    <h4 className="alert-heading">Listo!</h4>
                    <p className="mb-0">{message}</p>
                </>
            ) : (
                <>
                    <h4 className="alert-heading">Cuidado!</h4>
                    <p className="mb-0">
                        ¿Estas segurx que quieres eliminar el producto:{" "}
                        <b>{prod.name}</b>, con el id:<b>{prod._id}</b> y todos sus
                        subproductos, es decir colores o variantes del mismo?
                    </p>
                    <button
                        className="btn btn-outline-danger"
                        onClick={() => deleteProduct(prod._id)}
                    >
                        Si, eliminar
                    </button>
                </>
            )}
        </div>
    );
}

export default DeleteProduct;
