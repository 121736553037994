// import { useEffect } from "react";
import React from "react";

import { FormEvent } from "react";
import { ISubProduct } from "../../../interfaces/Item";
import NewSubProduct from "./NewSubProduct";

interface Props {
    cantidad: number,
    addSubProduct:(sub: ISubProduct)=>void,
    Submit:(e: FormEvent<HTMLButtonElement>)=>void,
    submitted:boolean
  };
  

function NewSubProductsContainer({ cantidad,addSubProduct,Submit,submitted}: Props) {
    const generateForms = () => {
      const elements = []
        for (let i = 0; i < cantidad; i++) {
            const element = <NewSubProduct number={i} key={`Subprod-n-${i}`}addSubProduct={addSubProduct} Submit={Submit}submitted={submitted}/>;
            elements.push(element)

          }
          return elements;
    };
    return <div>{generateForms()}</div>;
}

export default NewSubProductsContainer;
