import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ItemDetail from "./ItemDetail";
import { IItem, ISubProduct } from "../../interfaces/Item";
import Loading from "../../Loading/Loading";
const API_URL = process.env.REACT_APP_API_URL;


function ItemDetailContainer() {
    const [product, setProduct] = useState<IItem>();
    const [subProduct, setSubProduct] = useState<ISubProduct>();
    const [subProducts, setSubProducts] = useState<ISubProduct[]>();

    const [loading, setLoading] = useState<Boolean>(true);

    const { idProduct } = useParams();

    const fetchDataSubProduct = async () => {
        if (product) {
            setSubProduct(product.IDSubProducts[0]);
        }
    };
    const fetchDataSubProducts = async () => {
        if (product) {
            setSubProducts(product.IDSubProducts);
        }
    };
    useEffect(() => {
        if (product) {
            fetchDataSubProduct();
            fetchDataSubProducts();
        }
    }, [product]);
    useEffect(() => {
        try {
            if (idProduct) {
                const fetchData = async () => {
                    axios
                        .get(`${API_URL}/api/products/${idProduct}`)
                        .then((response) => {
                            const prod = response.data;
                            setProduct(prod);
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            setLoading(false);
                        });
                };
                fetchData();
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }, [idProduct]);

    const changeSubProduct = async (IDSubProduct: string) => {
        await axios
            .get(`${API_URL}/api/subproducts/${IDSubProduct}`)
            .then((subprodu) => {
                setSubProduct(subprodu.data);
            })
            .catch((error) => console.log(error));
    };

    return (
        <>
            {" "}
            {product && subProduct && subProducts ? (
                <ItemDetail
                    product={product}
                    subProduct={subProduct}
                    subProducts={subProducts}
                    changeSubProduct={changeSubProduct}
                />
            ) : (
                <>
                    {loading ? (
                        <Loading />
                    ) : (
                        `No hay producto coincidente con el id ${idProduct}`
                    )}
                </>
            )}
        </>
    );
}

export default ItemDetailContainer;
