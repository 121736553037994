import React from "react";

import { Link } from "react-router-dom";
import "./Error404.scss";

function Error404() {
    return (
        <section className="error404">
            <h1>Error 404</h1>
            <p>Lamentablemente no podemos acceder a dicho contenido.</p>
            <p>
                Haz click en la siguiente imagen para volver a la pagina
                principal:
            </p>
            <Link to="/">
                <img
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="Logo de Rayuela"
                />
            </Link>
        </section>
    );
}

export default Error404;
