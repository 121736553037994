import React from "react";
import { Link } from "react-router-dom";
import Button1 from "../../buttons/Button1/Button1";

function Pending() {
    return (
        <div className="pages">
            <h1>El pago está pendiente</h1>
            <p>Termina de realizar el pago</p>
            <p>Puedes ver tus ordenes en:</p>
            <Link to="/mis-pedidos">
                <Button1 text="Ver mis ordenes" />
            </Link>
        </div>
    );
}

export default Pending;
