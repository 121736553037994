import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useProductContext } from '../../context/productsContext'
import Aside from '../Aside/Aside'
import ItemListContainer from '../Item/ItemListContainer'
import './MainProducts.scss'

function MainProducts() {
    let location =  useLocation(); 
    const { category, brand } = useParams();
    const {updateCategory,updateBrand} = useProductContext()
    useEffect(()=>{
        updateCategory(category);
        updateBrand(brand);
    },[brand, category, updateBrand, updateCategory]);
    return (
        <main className='MainProducts'>
            <Aside/>
            <ItemListContainer/>
        </main>
    )
}

export default MainProducts