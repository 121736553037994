import React from "react";
import "../pages.scss";

function SobreNosotros() {
    return (
        <div className="pages">
            <h1>¿Quienes somos?</h1>
            <p>
                Rayu es un emprendimiento familiar, que nació en la localidad de
                San Andrés de Giles, provincia de Buenos Aires. En sus inicio se
                llamaba "Rayuela" y junto con la marca "Deluxe Freedom",
                conformaban una librería y un negocio de ropa en el mismo local.
            </p>
            <p>
                Durante el segundo año de vida "Rayuela" pasó a ser la unica
                marca, agregando también artículos de kiosco y almacén. De aquí
                en mas "Kiosco y librería Rayuela".
            </p>
            <p>
                Como la mayoría de los negocios físicos "Rayuela" atravesó la
                pandemia del covid-19, con todo lo que ello implicó, toda la
                familia hizo lo posible porque continuara en nuestras vidas,
                pero aún así, con el pasar de los días, nos fuimos haciendo a la
                idea de darle fin a "Rayuela", ya que no era redituable, y así
                fué en el año 2022 luego de mucho pensarlo cerramos las puertas
                de nuestro emprendimiento tan amado. Pero mientras todo esta
                ocurría, uno de los integrantes de la familia, se decidió por
                estudiar desarrollo web, luego de varias versiones, Rayu ahora
                con este nuevo nombre, resurgió de las cenizas con la esperanza
                de brindar una excelente atención a todxs lxs argentinxs, como
                en un origen lo hicimos en nuestra querida ciudad, toda una
                familia ahora al servicio de un país entero.
            </p>
            <p>Gracias por ser parte de <b>Rayu</b>.</p>
        </div>
    );
}

export default SobreNosotros;
