import React,{ ChangeEvent, FormEvent, useEffect, useState } from "react";
import { ISubProduct } from "../../../interfaces/Item";

interface Props {
    number: number;
    addSubProduct: (sub: ISubProduct) => void;
    Submit:(e: FormEvent<HTMLButtonElement>)=>void,
    submitted:boolean
}
function NewSubProduct({ number, addSubProduct,Submit,submitted }: Props) {
    let [cantImgs, setCantImgs] = useState<number>(1);
    // let [imgs, setImgs] = useState<number>([]);
    const initialForm ={
        color: "",
        stock: 0,
        img: [],
        imgFiles: [],
        creationDate: new Date(),
        barcode: "",
    }
    const [subProductForm, setSubProductForm] = useState<ISubProduct>(initialForm);
    useEffect(() => {
        if (submitted) {
            addSubProduct(subProductForm);
        } else {
            setSubProductForm(initialForm);
        }
    }, [submitted]);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const files = e.target.files;
        if (files) {
            const fileArray: File[] = Array.from(files);
            setSubProductForm((prevState) => ({
                ...prevState,
                imgFiles: fileArray,
            }));
        }
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const { name, value } = e.target;
        setSubProductForm({ ...subProductForm, [name]: value });
    };
    const handleChangeImg = (
        e: ChangeEvent<HTMLInputElement>,
        index: number
    ) => {
        e.preventDefault();
        const { value } = e.target;
        setSubProductForm((prevState) => {
            const newImgArray = [...prevState.img];
            newImgArray[index] = value;
            return { ...prevState, img: newImgArray };
        });
    };
    const generateFormsImgs = () => {
        const elements = [];
        for (let i = 0; i < cantImgs; i++) {
            const element = (
                <input
                    name="img"
                    value={subProductForm.img[i] || ""}
                    onChange={(e) => handleChangeImg(e, i)}
                    type="text"
                    className="form-control"
                    id="img"
                    multiple
                    placeholder={`image-n-${i + 1}`}
                    key={`${number}-image-n-${i}`}
                />
            );
            elements.push(element);
        }
        return elements;
    };
    return (
        <div>
            <h2>Subproducto número {number + 1}</h2>
            <section>
                <div className="form-group">
                    <label htmlFor="color">Color*</label>
                    <input
                        name="color"
                        value={subProductForm.color}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        className="form-control"
                        id="color"
                        placeholder="Color*"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="barcode">Código de barras</label>
                    <input
                        name="barcode"
                        value={subProductForm.barcode}
                        onChange={(e) => handleChange(e)}
                        type="text"
                        className="form-control"
                        id="barcode"
                        placeholder="Nombre del producto"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="stock">Stock*</label>
                    <input
                        name="stock"
                        value={subProductForm.stock}
                        onChange={(e) => handleChange(e)}
                        type="number"
                        className="form-control"
                        id="stock*"
                        placeholder="Nombre del producto"
                        required
                    />
                </div>
            </section>
            <br />
            <section>
                <div className="form-group">
                    <label htmlFor="files-img-subProduct">Sube imágenes:</label>
                    <input
                        type="file"
                        className="form-control"
                        id="files-img-subProduct"
                        name="files-img-subProduct"
                        multiple
                        onChange={(e) => handleFileChange(e)}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="imagen">O escribe sus URL:</label>
                    {generateFormsImgs()}
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                            cantImgs > 1 && setCantImgs(cantImgs - 1);
                        }}
                    >
                        -
                    </button>
                    <button
                        type="button"
                        className="btn btn-warning"
                        onClick={() => setCantImgs(cantImgs + 1)}
                    >
                        +
                    </button>
                </div>
            </section>
            <hr />
        </div>
    );
}

export default NewSubProduct;
