import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { IItem } from "../interfaces/Item";
import { useParams } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_URL;

type ProductsContextType = {
    products: IItem[];
    showProducts: IItem[];
    updateShowProducts: ({ products }: PropsUpdateShowProducts) => void;
    updateCategory: (category: String | undefined) => void;
    updateBrand: (brand: String | undefined) => void;
    loading: Boolean;
    categoryContext: String | undefined;
    brandContext: String | undefined;
    setSort:(text: ISort) => void;
};
interface Props {
    children: JSX.Element | JSX.Element[];
}
type PropsUpdateShowProducts = {
    products: IItem[];
};
export interface ISort {
    sort: "SortByName" | "SortByMinorPrice" | "SortByMajorPrice";
}
const productsContext = createContext<ProductsContextType | null>(null);
export const useProductContext = () => {
    return useContext(productsContext) as ProductsContextType;
};

function ProductsContextProvider({ children }: Props) {
    const [categoryContext, setCategoryContext] = useState<String>();
    const [brandContext, setBrandContext] = useState<String>();
    const [products, setProducts] = useState<IItem[]>([]);
    const [showProducts, setShowProducts] = useState<IItem[]>([]);
    const [loading, setLoading] = useState<Boolean>(true);
    const [sort, setSort] = useState<ISort>({ sort: "SortByName" });

    const updateCategory = (category: String | undefined) => {
        setCategoryContext(category);
    };
    const updateBrand = (brand: String | undefined) => {
        setBrandContext(brand);
    };

    const { category, brand } = useParams();

    useEffect(()=>{
        if (category && brand) {
            setCategoryContext(category);
            setBrandContext(brand);
        }
    },[category, brand])
    useEffect(() => {
        
        const queryParams =`sort=${sort.sort}`
        const fetchData = async () => {
            try {
                const generalProducts = await axios.get(
                    `${API_URL}/api/products/?${queryParams}`
                    );
                    setProducts(generalProducts.data);
                    let productos;
                    if (categoryContext) {
                        console.log({categoryContext});
                        const URL = `${API_URL}/api/products/category/${categoryContext}?${queryParams}`;
                        await axios
                        .get(URL)
                        .then((prod) => {
                            setShowProducts(prod.data);
                        });
                    } else if (brandContext) {
                        productos = await axios.get(
                            `${API_URL}/api/products/brand/${brandContext}?${queryParams}`
                            );
                            setShowProducts(productos.data);
                            console.log(
                                `${API_URL}/api/products/brand/${brandContext}?${queryParams}`,
                                { params: queryParams }
                                );
                            } else {
                        console.log({categoryContext,m:'no entro a los anteriores'});
                        productos = await axios.get(`${API_URL}/api/products?${queryParams}`);
                        setLoading(false);
    
                        setShowProducts(productos.data);
                    }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [categoryContext, brandContext,sort]);

    const updateShowProducts = ({ products }: PropsUpdateShowProducts) => {
        setShowProducts(products);
    };

    return (
        <productsContext.Provider
            value={{
                products,
                showProducts,
                updateShowProducts,
                updateCategory,
                updateBrand,
                loading,
                categoryContext,
                brandContext,
                setSort
            }}
        >
            {children}
        </productsContext.Provider>
    );
}

export default ProductsContextProvider;
