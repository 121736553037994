import React, { useState } from "react";
import Button1 from "../../../buttons/Button1/Button1";
import { IUser } from "../../../../interfaces/User";
import axios from "axios";
import "./EditInfoUser.scss";
import { useUserContext } from "../../../../context/userContext";
interface Props {
    setIsEditing: (value: boolean) => void;
    user: IUser;
}
interface Direction {
        address?: string;
        city?: string;
        prov?: string;
        CP?: number;
}

function EditInfoUser({ setIsEditing, user}: Props) {
    const [name, setName] = useState<string>(user.name || "");
    const [lastName, setLastName] = useState<string>(user.lastName || "");
    const [phone, setPhone] = useState<number|undefined>(user.phone || undefined);
    const [direction, setDirection] = useState<Direction>(user.direction || {});
    const urlApi = process.env.REACT_APP_API_URL;
    const {setUser}= useUserContext()

    const sendForm = () => {
        const data ={
            name,lastName,phone,direction
        }
        const token = localStorage.getItem("authToken");
        const fetchData=async()=>{
            try {
                const newUser=await axios.put(`${urlApi}/api/user/update-user/${user._id}`,data,{
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                })
                newUser && setUser(newUser.data)

            } catch (error) {
                console.log(error);
                
            }
        }
        fetchData()
        setIsEditing(false);
    };
    return (
        <div className="EditInfoUser">
            <form /* action="put" method="put" */>
                {/* <label htmlFor="email">Dirección de Email</label>
                <input
                    type="email"
                    placeholder="nombre@ejemplo.com"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                /> */}
                <h2>Complete con sus datos para poder recibir envíos</h2>
                <label htmlFor="name">Nombre</label>
                <input
                    type="text"
                    placeholder="Introduce tu nombre..."
                    name="name"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <label htmlFor="lastname">Apellido</label>
                <input
                    type="text"
                    placeholder="Introduce tu apellido"
                    name="lastname"
                    id="lastname"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
                <label htmlFor="phone">Teléfono</label>
                <input
                    type="number"
                    placeholder="Introduce tu número telefónico"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(parseInt(e.target.value))}
                />
                <h3>Dirección</h3>
                <label htmlFor="address">Dirección (incluye calle, el numero de casa/edificio y departamento)</label>
                <input
                    type="string"
                    placeholder="Introduce tu calle, el número de casa/edificio y departamento"
                    name="address"
                    id="address"
                    value={direction?.address}
                    onChange={(e) => setDirection({...direction,address:e.target.value})}
                />{" "}
                <label htmlFor="CP">Código postal</label>
                <input
                    type="number"
                    placeholder="Introduce tu código postal..."
                    name="CP"
                    id="CP"
                    min="0"
                    value={direction.CP}
                    onChange={(e) => setDirection({...direction,CP:parseInt(e.target.value)})}
                />{" "}
                <label htmlFor="city">Ciudad</label>
                <input
                    type="string"
                    placeholder="Introduce tu ciudad..."
                    name="city"
                    id="city"
                    value={direction.city}
                    onChange={(e) => setDirection({...direction,city:e.target.value})}
                />{" "}
                <label htmlFor="prov">Provincia</label>
                <input
                    type="string"
                    placeholder="Introduce tu provincia"
                    name="prov"
                    id="prov"
                    value={direction.prov}
                    onChange={(e) => setDirection({...direction,prov:e.target.value})}
                />
                <div onClick={() => sendForm()}>
                    <Button1 text="Guardar información" />
                </div>
            </form>
        </div>
    );
}

export default EditInfoUser;
