import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { IUser } from "../interfaces/User";
const urlApi = process.env.REACT_APP_API_URL;

type UserContextType = {
    user: IUser | undefined;
    updateInfoUser: (authToken: string) => void;
    closeSession: () => void;
    setUser: (user:IUser)=>void
    saveTokenToLocalStorage:(token:string|null)=>void
    getToken: () => void;
};
interface Props {
    children: JSX.Element | JSX.Element[];
}

const userContext = createContext<UserContextType | null>(null);
export const useUserContext = () => {
    return useContext(userContext) as UserContextType;
};

function UserContextProvider({ children }: Props) {
    const [user, setUser] = useState<IUser>();

    const getToken =()=>{
        const token = localStorage.getItem("authToken");
        return token
    }
    const saveTokenToLocalStorage = (token: string | null) => {
        return new Promise<void>((resolve, reject) => {
            try {
                token && localStorage.setItem("authToken", token);
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
    const closeSession = () => {
        setUser(undefined);
    };

    const fetchData = async (token: string) => {
        try {
            const response = await axios.get(`${urlApi}/api/user/profile`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setUser(response.data.selectedUserInfo);
        } catch (error) {
            console.log(error);
        }
    };
    const updateInfoUser = async (authToken: string) => {
        fetchData(authToken);
    };
    useEffect(() => {
        const token = localStorage.getItem("authToken");
        token && fetchData(token);
    }, []);

    return (
        <userContext.Provider
            value={{
                user,
                updateInfoUser,
                closeSession,
                setUser,
                saveTokenToLocalStorage,
                getToken
            }}
        >
            {children}
        </userContext.Provider>
    );
}

export default UserContextProvider;
