import React from 'react'
import { Link } from 'react-router-dom';
import { IItem } from "../../interfaces/Item";
import Item from './Item';
import './ItemList.scss'
interface Props{
    products: Array<IItem>
} 
const ItemList = ({products}:Props ) => {
    if (!Array.isArray(products)) {
        console.error('Error: products no es un array', products);
        return null; // O manejar el error de alguna otra manera
    }
    return (
        <div className='itemList'>
            {products.map((product:IItem) =>
            <Link to={`/detalle/${product._id}`} key={product._id}>
                <Item product={product}/>
            </Link>
            )}
        </div>
        )
}

export default ItemList