import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
import './GoTop.scss'
const GoTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
      // Verifica si el usuario ha hecho scroll más allá de una cierta posición (puedes ajustar este valor según tus necesidades)
      setIsVisible(window.scrollY > 100);
    };
  
    useEffect(() => {
      // Agrega un event listener para el evento de scroll
      window.addEventListener("scroll", handleScroll);
  
      // Limpia el event listener al desmontar el componente
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
  
    const goTop =()=>{
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }
    return (<>
    {isVisible?
        <div className="GoTop" onClick={()=>goTop()}>
            <FaArrowUp />
        </div>
    
    :<></>}</>
    );
};

export default GoTop;
