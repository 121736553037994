import React, { useState } from "react";
import { useUserContext } from "../../context/userContext";
import EditInfoUser from "../Acount/InfoUser/EditInfoUser/EditInfoUser";
import Button1 from "../buttons/Button1/Button1";
import "./Orders.scss";
import Button2 from "../buttons/Button2/Button2";
import Direction from "../Acount/InfoUser/Direction";
import { Link } from "react-router-dom";

function Orders() {
    const { user } = useUserContext();
    const [isEditing, setIsEditing] = useState(false);


    return (
        <div className="Orders">
            {user && (
                <>
                    {user?.direction?.CP &&
                    user?.direction?.address &&
                    user?.direction?.prov &&
                    user?.direction?.city /* &&
                    isEditing === false  */ ? (
                        <>
                            {isEditing ? (
                                <EditInfoUser
                                    setIsEditing={setIsEditing}
                                    user={user}
                                />
                            ) : (
                                <div>
                                    <h1>¿Quiere utilizar esta dirección?</h1>
                                    <Direction user={user} />
                                    <Link to="/buy">
                                        <Button1 text="Usar ésta dirección" />
                                    </Link>
                                    <div onClick={() => setIsEditing(true)}>
                                        <Button2 text="Modificarla" />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            {isEditing ? (
                                <EditInfoUser
                                    setIsEditing={setIsEditing}
                                    user={user}
                                />
                            ) : (
                                <>
                                    Es importante que llene todos sus datos, en
                                    especial Dirección, Código Postal, Ciudad y
                                    Provincia.
                                    <div onClick={() => setIsEditing(true)}>
                                        <Button1 text={"LLenar datos"} />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default Orders;
