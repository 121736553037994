import React, { useState } from "react";
import "./SimpleTable.scss";
import {
    RowModel,
    Table,
    useReactTable,
    getCoreRowModel,
    flexRender,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    Column,
    getFilteredRowModel,
} from "@tanstack/react-table";
import { IOrder } from "../../interfaces/Orders";
import Button1 from "../buttons/Button1/Button1";

interface Props {
    orders: IOrder[];
    columns: {
        header: string;
        accessorKey?: string;
        accessorFn?: (row:any)=>void;
        footer: string;
    }[];
}

function SimpleTable({ orders, columns }: Props) {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [filtering, setFiltering] = useState('');

    const table = useReactTable({
        data: orders,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel:getFilteredRowModel(),
        state: { sorting,
        globalFilter:filtering },
        onSortingChange: setSorting,
        onGlobalFilterChange:setFiltering
    });
    return (
        <div className="SimpleTable">
            <input
            type="text"
            value={filtering}
            onChange={e=>setFiltering(e.target.value)}/>
            <table>
                <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map((header) => (
                                <th
                                    key={header.id}
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {header.isPlaceholder ? null : (
                                        <div
                                            {...{
                                                className:
                                                    header.column.getCanSort()
                                                        ? "cursor-pointer select-none"
                                                        : "",
                                                onClick:header.column.getToggleSortingHandler()
                                            }}
                                        >
                                            {flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                            {/* {
                                                { asc: "👆", desc: "👇" }[
                                                    header.column.getIsSorted() ?? null
                                                  ]
                                            } */}
                                        </div>
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <td>
                                    {flexRender(
                                        cell.column.columnDef.cell,
                                        cell.getContext()
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    {table.getFooterGroups().map((footerGroup) => (
                        <tr key={footerGroup.id}>
                            {footerGroup.headers.map((footer) => (
                                <th key={footer.id}>
                                    {flexRender(
                                        footer.column.columnDef.footer,
                                        footer.getContext()
                                    )}
                                </th>
                            ))}
                        </tr>
                    ))}
                </tfoot>
            </table>
            <div className="buttons">
                <div onClick={() => table.setPageIndex(0)}>
                    <Button1 text="Primera página" />
                </div>
                <div onClick={() => table.previousPage()}>
                    <Button1 text="Página anterior" />
                </div>
                <div onClick={() => table.nextPage()}>
                    <Button1 text="Página siguiente" />
                </div>
                <div
                    onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                >
                    <Button1 text="Última página" />
                </div>
            </div>
        </div>
    );
}

export default SimpleTable;
