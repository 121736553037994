import React from "react";
import "../pages.scss";
import "./FormasDePago.scss";
import { Card } from "react-bootstrap";

function FormasDePago() {
    return (
        <div className="pages">
            <h1>Formas de pago</h1>
            <p>En Rayu usamos la pasarela de pagos de Mercado libre.</p>
            <div>
                <p>
                    <strong>Y podés pagar con cualquiera de estos medios:</strong>
                </p>
                <div className="conatinerCards">

                <Card className="card">
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                        <Card.Title>
                            <h2>Tarjeta de crédito</h2>
                        </Card.Title>
                        <Card.Text>
                            <p>
                                Algunas tarajetas ofrecen <strong>cuotas sin interés</strong>
                            </p>
                            <a href="http://www.mercadolibre.com.ar/gz/promociones-bancos">
                                Ver promociones bancarias
                            </a>
                            <p>Acreditación instantánea</p>
                            <a
                                href="http://ayuda.mercadolibre.com.ar/ayuda/Costos-de-financiaci-n-por-pag-ml_2955"
                                title="Conoce los bancos o tarjetas con promoción de cuotas sin interés para pagar en Mercado Libre."
                            >
                                Costo de financiación para tarjetas de crédito
                            </a>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="card">
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                        <Card.Title>
                            <h2>Tarjeta de débito</h2>
                        </Card.Title>
                        <Card.Text>
                            <p>1 pago</p>
                            <p>Acreditación instantánea</p>

                            <h3>En cuotas sin usar tarjeta:</h3>
                            <p>
                                Seleccioná Mercado Crédito como medio de pago,
                                elegí las cuotas, ¡y listo!
                            </p>
                            <p>Hasta 12 cuotas</p>
                            <p>Acreditación instantánea</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="card">
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                        <Card.Title>
                            <h2>Dinero en Mercado Pago</h2>
                        </Card.Title>
                        <Card.Text>
                            <p>
                                Se transfiere al instante a la cuenta del
                                receptor.
                            </p>
                            <p>1 pago</p>
                            <p>Acreditación instantánea</p>
                        </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="card">
                    {/* <Card.Img variant="top" src="holder.js/100px180" /> */}
                    <Card.Body>
                        <Card.Title>
                            <h2>Pagos en efectivo</h2>
                        </Card.Title>
                        <Card.Text>
                            <ul>
                                <li>Rapipago</li>
                                <li>Pago Fácil</li>
                            </ul>

                            <h3>En efectivo con Rapipago y Pago Fácil:</h3>
                            <p>Te damos un número para pagar.</p>
                            <p>1 pago</p>
                            <p>
                                Rapipago se acredita al instante y Pago Fácil en
                                2 horas después de pagar.
                            </p>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
            </div>
        </div>
    );
}

export default FormasDePago;
