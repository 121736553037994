import React from 'react'
import './Button3.scss'
type Props = {
    text:string
}
function Button3({text}:Props) {
    return (
        <button className='Button3'>
            {text}
        </button>
    )
}

export default Button3