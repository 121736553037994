import React from "react";
import { ISubProduct } from "../../../interfaces/Item";
import SelectColor from "./SelectColor";

// interface Colors{
//     color:string,
//     _id:string,
// }
interface IProps{
    subProducts:ISubProduct[],
    changeSubProduct:(IDSubProduct:string)=>void
}
function SelectColorContainer({subProducts,changeSubProduct}:IProps) {
    return <div>
        {
            subProducts[1] ?
                <SelectColor subProducts={subProducts} changeSubProduct={changeSubProduct}/>
            :
                <div></div>
        }
    </div>;
}

export default SelectColorContainer;
