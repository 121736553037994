import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { ICart } from "../interfaces/ICart";
import { useUserContext } from "./userContext";
const urlApi = process.env.REACT_APP_API_URL;

type CartContextType = {
    cart: ICart | undefined;
    totalPriceInCart: number | undefined;
    quantityInCart: number | undefined;
    updateInfoCart: () => void;
    cleanCart: () => void;
    deleteProductOfCart: (idSubProduct: string) => void;
    addOrUpdateSubProductToCart: ({idSubProduct,quantity}:IAddOrUpdateSubProductToCart) => void;
};
type IAddOrUpdateSubProductToCart = {
    idSubProduct: string |undefined;
    quantity: number |undefined;
};
interface Props {
    children: JSX.Element | JSX.Element[];
}

const userContext = createContext<CartContextType | null>(null);
export const useCartContext = () => {
    return useContext(userContext) as CartContextType;
};

function CartContextProvider({ children }: Props) {
    const [cart, setCart] = useState<ICart>();
    const [quantityInCart, setQuantityInCart] = useState<number>(0);
    const [totalPriceInCart, setTotalPriceInCart] = useState<number>();
    const token = localStorage.getItem("authToken") || undefined;
    const { user } = useUserContext();

    const totalPrice = () => {
        const arrayTotals = cart?.products.map((prod) => {
            const quantity = prod.quantity;
            const price = prod.subProduct?.IDProduct?.price;

            if (quantity !== undefined && price !== undefined) {
                return quantity * price;
            }
            return 0;
        });
        const suma = arrayTotals
            ? arrayTotals.reduce((a, b) => a + (b ?? 0), 0)
            : 0;
        setTotalPriceInCart(suma);
    };

    const updateQuantityInCart = () => {
        if(user){
            const arrayTotals = cart?.products.map((prod) => {
                return prod.quantity;
            });
            const suma = arrayTotals
                ? arrayTotals.reduce((a, b) => a + (b ?? 0), 0)
                : 0;
                setQuantityInCart(suma);
                
            }else{
                setQuantityInCart(0);
                
        }
    };
    const fetchData = async (token: string | undefined) => {
        try {
            if (user && token) {
                const response = await axios.get(
                    `${urlApi}/api/carts/${user.cartId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                setCart(response.data);
            } else {
                setCart(undefined);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const addOrUpdateSubProductToCart = async ({
        idSubProduct,
        quantity,
    }: IAddOrUpdateSubProductToCart) => {
        const dataBody = {
            idSubProduct: idSubProduct,
            quantity: quantity,
        };
        try {
            // const response =
                cart &&
                (await axios.post(
                    `${urlApi}/api/carts/add-subproducts/${cart._id}`,
                    dataBody,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                )
                updateInfoCart();
        } catch (error) {
            console.error("Error al agregar al carrito:", error);
        }
    };
    const updateInfoCart = async () => {
        if (token) {
            await fetchData(token);
            console.log('se esta ejecutando updateInfoCart');
            
        }
    };

    const deleteProductOfCart = async (idSubProduct: string) => {
        try {
            await axios.delete(
                `${urlApi}/api/carts/delete-product-of-cart/${cart?._id}/${idSubProduct}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            updateInfoCart();
        } catch (error) {
            console.log(error);
        }
    };
    const cleanCart = async () => {
        try {
            await axios.delete(`${urlApi}/api/carts/clear-cart/${cart?._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            updateInfoCart();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        token && fetchData(token);
    }, [user]);
    useEffect(() => {
            totalPrice();
            updateQuantityInCart();
    }, [cart]);

    return (
        <userContext.Provider
            value={{
                cart,
                totalPriceInCart,
                quantityInCart,
                // quantityInCart,
                updateInfoCart,
                cleanCart,
                deleteProductOfCart,
                addOrUpdateSubProductToCart,
            }}
        >
            {children}
        </userContext.Provider>
    );
}

export default CartContextProvider;
