import React, { useState } from "react";
import { IItem } from "../../interfaces/Item";
import SubProductAdmin from "./SubProductAdmin";
import { AiFillDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { GrAddCircle } from "react-icons/gr";

import DeleteProduct from "./Delete/DeleteProduct";
import EditProductInLine from "./Edit/EditProductInLine";
import { Link } from "react-router-dom";
interface Prop {
    prod: IItem;
    showSubProducts: boolean;
}
function ProductAdmin({ prod, showSubProducts }: Prop) {
    const [tryDelete, setTryDelete] = useState<boolean>(false);
    const [edit, setEdit] = useState<boolean>(false);

    return (
        <>
            <tr className="table-dark" key={prod._id}>
                <td>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setEdit(!edit)}
                    >
                        <CiEdit />
                    </button>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => setTryDelete(true)}
                    >
                        <AiFillDelete />
                    </button>
                    <Link to={`/admin/add-more-sub-product/${prod._id}`}>
                        <button
                            type="button"
                            className="btn btn-warning"
                            // onClick={() =>}
                        >
                            <GrAddCircle />
                        </button>
                    </Link>
                </td>
                <td>{prod.name}</td>
                <td>
                    <p>
                        <button
                            className="btn btn-secondary"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${prod._id}`}
                            aria-expanded="false"
                            aria-controls={`collapse${prod._id}`}
                        >
                            Cant de caracteres de la descripción:{" "}
                            {prod.description.length}
                        </button>
                    </p>
                    <div className="collapse" id={`collapse${prod._id}`}>
                        <div className="card card-body bg-dark">
                            {prod.description}
                            <button
                                className="btn btn-danger"
                                type="button"
                                data-toggle="collapse"
                                data-target={`#collapse${prod._id}`}
                                aria-expanded="false"
                                aria-controls={`collapse${prod._id}`}
                            >
                                Cerrar descripción
                            </button>
                        </div>
                    </div>
                </td>
                <td>{prod.price}</td>
                <td>{prod.category}</td>
                <td>{prod.brand}</td>
                <td>
                    {new Date(prod.creationDate).toLocaleDateString("es-ES", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
                </td>
                <td>
                    {prod.lastModifiedDate
                        ? new Date(prod.lastModifiedDate).toLocaleDateString(
                                "es-ES",
                                {
                                    day: "numeric",
                                    month: "long",
                                    year: "numeric",
                                }
                            )  
                        : "todavía no fué modificado"}
                </td>
                <td>
                    {/* {JSON.stringify(prod)} */}
                    Largo: {prod.size?.long} x Ancho:{prod.size?.width} x
                    Altura:{prod.size?.height}
                </td>
                <td>{prod.weight}</td>
                <td>{prod.minimumStock}</td>
                <td>{prod?.discount}</td>
            </tr>
            {edit && <EditProductInLine prod={prod} setEdit={setEdit} />}
            {showSubProducts &&
                prod.IDSubProducts.map((subProd) => (
                    <SubProductAdmin id={subProd._id} key={subProd._id} />
                ))}
            {tryDelete && (
                <DeleteProduct prod={prod} setTryDelete={setTryDelete} />
            )}
        </>
    );
}

export default ProductAdmin;
