import React from 'react'
import { Link } from 'react-router-dom'

function Ofertas() {
  return (
    <div className='pages'>
        <h1>Ofertas</h1>
        <p>Proximamente aquí aparecerán los productos con descuentos.</p>
        <Link to={"/"} className="logo">
                <img
                style={{width:'30vw'}}
                    className="logo"
                    src={process.env.PUBLIC_URL + "/images/logo.png"}
                    alt="Logo de Rayuela"
                />
            </Link>
    </div>
  )
}

export default Ofertas