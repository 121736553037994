import React from "react";
import { NavLink } from "react-router-dom";
import Button1 from "../buttons/Button1/Button1";
import './Footer.scss'
function Footer() {
    return(
    <footer>
        <div className="mailFooter">
            <form>
                <ul>
                    <li>
                        <label htmlFor="emailFooter">Recibí ofertas y promociones</label>
                        <input type="email" id="emailFooter" placeholder="Ingresa aquí tu email"/>
                    </li>
                    <li>
                        <Button1 text='Suscribirme'/>
                    </li>
                </ul>
            </form>
        </div>
        <div className="infoFooter">
            <h2>Información</h2>
            <nav>
                <ul>
                    <li><NavLink end to="/sobre-nosotros">Quienes somos</NavLink></li>
                    <li><NavLink end to="/formasDePago">Formas de pago</NavLink></li>
                    <li><NavLink end to="/comoLlegar">Como llegar</NavLink></li>
                    <li><NavLink end to="/contacto">Contacto</NavLink></li>
                </ul>
            </nav>
        </div>
        <div className="divRedesSociales">
            <h2>Sigamos conectados</h2>
            <ul>
                {/* <!-- <p>telegram</p> Todavía no tiene --> */}
                <li><a href="https://www.facebook.com/Kiosco-y-librer%C3%ADa-Rayuela-255957498683723" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL +"/images/icons/facebook.png"} alt="Facebook" className="logoRedSocial"/></a></li>
                <li><a href="https://www.instagram.com/rayu_ar/" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL +"/images/icons/instagram.png"} alt="Instagram" className="logoRedSocial"/></a></li>
                <li><a href="https://wa.me/message/SJG2ZPAF76GTC1" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL +"/images/icons/whatsapp.png"} alt="Whatsapp" className="logoRedSocial"/></a></li>
            </ul>
        </div>
    </footer>
    )
}

export default Footer;